export default {
  publicUrl: '/newui',

  backendUrl: process.env.REACT_APP_BACKEND_URL || 'http://localhost:8181',
  s3BucketUrl: process.env.REACT_APP_S3_BUCKET_URL || 'http://localhost:4572/saavi',

  newBackendUrl: process.env.REACT_APP_NEW_BACKEND_URL,

  pdfDownloadTimeoutMs: 1800000,

  // Login providers
  msalClientId: "927dff93-d2f8-4e32-ab0b-39c8f2f1ce0a",
  googleOAuthClientId: "695875977442-4f9600n3etmhebn04atqu9i1i0b58aij.apps.googleusercontent.com",
  appleLoginClientId: "com.kotopro.app",
}
