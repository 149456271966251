import React from 'react';
import { ReactComponent as Folder } from './icons/icon-folder.svg';
import { ReactComponent as Superfolder } from './icons/icon-super-folder.svg';
import { ReactComponent as SuperfolderOpen } from './icons/icon-super-folder-open.svg';
import { ReactComponent as FolderOpen } from './icons/icon-folder-open.svg';
import { ReactComponent as ImageFolder } from './icons/icon-image-folder.svg';
import { ReactComponent as ImageFolderOpen } from './icons/icon-image-folder-open.svg';
import { ReactComponent as ImageAdd } from './icons/icon-image-add.svg';
import { ReactComponent as Document } from './icons/icon-document.svg';
import { ReactComponent as Documents } from './icons/icon-documents.svg';
import { ReactComponent as Image } from './icons/icon-image.svg';
import { ReactComponent as Diary } from './icons/icon-diary.svg';
import { ReactComponent as Tr1 } from './icons/icon-tr1.svg';
import { ReactComponent as ChevronSmallUp } from './icons/icon-chevron-small-up.svg';
import { ReactComponent as ChevronSmallDown } from './icons/icon-chevron-small-down.svg';
import { ReactComponent as ChevronSmallRight } from './icons/icon-chevron-small-right.svg';
import { ReactComponent as ChevronBigLeft } from './icons/icon-chevron-big-left.svg';
import { ReactComponent as ChevronBigRight } from './icons/icon-chevron-big-right.svg';
import { ReactComponent as More } from './icons/icon-more.svg';
import { ReactComponent as Key } from './icons/icon-key.svg';
import { ReactComponent as Archive } from './icons/icon-archive.svg';
import { ReactComponent as Report } from './icons/icon-report.svg';
import { ReactComponent as Loading } from './icons/icon-circle-o-notch.svg';
import { ReactComponent as Circle } from './icons/icon-circle.svg';
import { ReactComponent as Add } from './icons/icon-add.svg';
import { ReactComponent as Forward } from './icons/icon-forward.svg';
import { ReactComponent as Trash } from './icons/icon-trash.svg';
import { ReactComponent as TickboxEmpty } from './icons/icon-tickbox-o-empty.svg';
import { ReactComponent as TickboxFilled } from './icons/icon-tickbox-o-filled.svg';
import { ReactComponent as Search } from './icons/icon-search.svg';
import { ReactComponent as Pencil } from './icons/icon-pencil.svg';
import { ReactComponent as XSmall } from './icons/icon-x-small.svg';
import { ReactComponent as XBig } from './icons/icon-x-big.svg';
import { ReactComponent as Users } from './icons/icon-users.svg';
import { ReactComponent as ProfileAdd } from './icons/icon-profile-add.svg';
import { ReactComponent as Profile } from './icons/icon-profile.svg';
import { ReactComponent as Eye } from './icons/icon-eye.svg';
import { ReactComponent as EyeSlash } from './icons/icon-eye-slash.svg';
import { ReactComponent as Projects } from './icons/icon-projects.svg';
import { ReactComponent as Email } from './icons/icon-email.svg';
import { ReactComponent as Link } from './icons/icon-link.svg';
import { ReactComponent as Check } from './icons/icon-check.svg';
import { ReactComponent as Move } from './icons/icon-move.svg';
import { ReactComponent as Organize } from './icons/icon-organize.svg';

import { ReactComponent as InfoCircleO } from './icons/icon-info-circle-o.svg';
import { ReactComponent as ShowComments } from './icons/icon-show-comments.svg';
import { ReactComponent as ClockO } from './icons/icon-clock-o.svg';
import { ReactComponent as ExclamationO } from './icons/icon-exclamation-o.svg';
import { ReactComponent as Tags } from './icons/icon-tags.svg';

import { ReactComponent as Notifications } from './icons/icon-notifications.svg';

import { ReactComponent as Copy } from './icons/icon-copy.svg';
import { ReactComponent as SignOut } from './icons/icon-sign-out.svg';
import { ReactComponent as Color } from './icons/icon-color.svg';
import { ReactComponent as Fill } from './icons/icon-fill.svg';
import { ReactComponent as Stroke } from './icons/icon-stroke.svg';
import { ReactComponent as Pin } from './icons/icon-pin.svg';
import { ReactComponent as Arrow } from './icons/icon-arrow.svg';
import { ReactComponent as Rectangle } from './icons/icon-rectangle.svg';
import { ReactComponent as Download } from './icons/icon-download.svg';
import { ReactComponent as Oval } from './icons/icon-oval.svg';
import { ReactComponent as Line } from './icons/icon-line.svg';
import { ReactComponent as Font } from './icons/icon-font.svg';
import { ReactComponent as CurrentLocation } from './icons/icon-current-location.svg';
import { ReactComponent as SearchPlus } from './icons/icon-search-plus.svg';
import { ReactComponent as SearchMinus } from './icons/icon-search-minus.svg';
import { ReactComponent as RotateLeft } from './icons/icon-rotate-left.svg';
import { ReactComponent as RotateRight } from './icons/icon-rotate-right.svg';
import { ReactComponent as Pdf } from './icons/icon-pdf.svg';
import { ReactComponent as PdfExport } from './icons/icon-big-pdf-export.svg';
import { ReactComponent as Settings } from './icons/icon-settings.svg';
import { ReactComponent as Print } from './icons/icon-print.svg';
import { ReactComponent as Share } from './icons/icon-share.svg';
import { ReactComponent as Location } from './icons/icon-location.svg';
import { ReactComponent as CheckboxEmpty } from './icons/icon-checkbox-empty.svg';
import { ReactComponent as Microsoft } from './icons/icon-microsoft.svg';
import { ReactComponent as Apple } from './icons/icon-apple.svg';
import { ReactComponent as Create } from './icons/icon-create.svg';
import { ReactComponent as Question } from './icons/icon-question.svg';
import { ReactComponent as QuestionCircle } from './icons/icon-question-circle.svg';
import { ReactComponent as CreditCard } from './icons/icon-credit-card.svg';
import { ReactComponent as BigCreditCard } from './icons/icon-big-creditcard.svg';
import { ReactComponent as Fi } from './icons/fi.svg';
import { ReactComponent as En } from './icons/en.svg';
import { ReactComponent as Help } from './icons/icon-help.svg';
import { ReactComponent as Phone } from './icons/icon-phone.svg';
import { ReactComponent as Calendar } from './icons/icon-calendar.svg';
import { ReactComponent as ScreenmodeSystem } from './icons/icon-screenmode-system.svg';
import { ReactComponent as Wrench } from './icons/icon-wrench.svg';
import { ReactComponent as Minimize } from './icons/icon-minimize.svg';
import { ReactComponent as Maximize } from './icons/icon-maximize.svg';
import { ReactComponent as HideComments } from './icons/icon-hide-comments.svg';
import { ReactComponent as Close } from './icons/icon-close.svg';
import { ReactComponent as Expand } from './icons/icon-expand.svg';
import { ReactComponent as List } from './icons/icon-list.svg';
import { ReactComponent as Plus } from './icons/icon-plus.svg';
import { ReactComponent as TemplateDocument } from './icons/icon-template-document.svg';
import { ReactComponent as Text } from './icons/icon-text.svg';
import { ReactComponent as File } from './icons/icon-file.svg';
import { ReactComponent as TickboxCheckFilled } from './icons/icon-tickbox-check-filled.svg';
import { ReactComponent as Table } from './icons/icon-table.svg';
import { ReactComponent as BigSignature } from './icons/icon-big-signature.svg';
import { ReactComponent as Anchor } from './icons/icon-anchor.svg';
import { ReactComponent as ChevronBigDown } from './icons/icon-chevron-big-down.svg';
import { ReactComponent as Preview } from './icons/icon-preview.svg';
import { ReactComponent as InfoCircle } from './icons/icon-info-circle.svg';
import { ReactComponent as Filter } from './icons/icon-filter.svg';
import { ReactComponent as TemplateFolder } from './icons/icon-template-folder.svg';
import { ReactComponent as Sort2 } from './icons/icon-sort2.svg';
import { ReactComponent as ChevronBigUp } from './icons/icon-chevron-big-up.svg';
import { ReactComponent as ModalOpen } from './icons/icon-modal.svg';
import { ReactComponent as Minus } from './icons/icon-minus.svg';
import { ReactComponent as Camera } from './icons/icon-camera.svg';
import { ReactComponent as Robot } from './icons/icon-robot.svg';
import { ReactComponent as Company } from './icons/icon-company.svg';
import { ReactComponent as Refresh } from './icons/icon-refresh.svg';
import { ReactComponent as Undo } from './icons/icon-undo.svg';
import { ReactComponent as Lock } from './icons/icon-lock.svg';
import { ReactComponent as LockOpen } from './icons/icon-lock-open.svg';
import { ReactComponent as DocumentLocked } from './icons/icon-document-locked.svg';
import { ReactComponent as Favorite} from './icons/icon-favorite.svg';
import { ReactComponent as Vector } from './icons/icon-vector.svg';
import { ReactComponent as Star} from './icons/icon-star.svg';
import { ReactComponent as CheckCircle } from './icons/icon-check-circle.svg';

const icons = {
  folder: <Folder />,
  superfolder: <Superfolder />,
  superfolderOpen: <SuperfolderOpen />,
  folderOpen: <FolderOpen />,
  imageFolder: <ImageFolder />,
  imageFolderOpen: <ImageFolderOpen />,
  imageAdd: <ImageAdd />,
  chevronSmallUp: <ChevronSmallUp />,
  chevronSmallDown: <ChevronSmallDown />,
  chevronBigLeft: <ChevronBigLeft />,
  chevronBigRight: <ChevronBigRight />,
  chevronSmallRight: <ChevronSmallRight />,
  more: <More />,
  key: <Key />,
  archive: <Archive />,
  report: <Report />,
  loading: <Loading />,
  add: <Add />,
  document: <Document />,
  documents: <Documents />,
  image: <Image />,
  diary: <Diary />,
  tr1: <Tr1 />,
  forward: <Forward />,
  trash: <Trash />,
  tickboxEmpty: <TickboxEmpty />,
  tickboxFilled: <TickboxFilled />,
  search: <Search />,
  pencil: <Pencil />,
  xSmall: <XSmall />,
  xBig: <XBig />,
  users: <Users />,
  profileAdd: <ProfileAdd />,
  profile: <Profile />,
  eye: <Eye />,
  eyeSlash: <EyeSlash />,
  projects: <Projects />,
  email: <Email />,
  link: <Link />,
  check: <Check />,
  move: <Move />,
  organize: <Organize />,
  infoCircleO: <InfoCircleO />,
  showComments: <ShowComments />,
  clockO: <ClockO />,
  exclamationO: <ExclamationO />,
  tags: <Tags />,
  notifications: <Notifications />,
  copy: <Copy />,
  signOut: <SignOut />,
  color: <Color />,
  fill: <Fill />,
  stroke: <Stroke />,
  pin: <Pin />,
  arrow: <Arrow />,
  rectangle: <Rectangle />,
  download: <Download />,
  oval: <Oval />,
  line: <Line />,
  font: <Font />,
  currentLocation: <CurrentLocation />,
  searchPlus: <SearchPlus />,
  searchMinus: <SearchMinus />,
  rotateLeft: <RotateLeft />,
  rotateRight: <RotateRight />,
  pdf: <Pdf />,
  pdfExport: <PdfExport />,
  settings: <Settings />,
  print: <Print />,
  create: <Create />,
  share: <Share />,
  location: <Location />,
  checkboxEmpty: <CheckboxEmpty />,
  microsoft: <Microsoft />,
  apple: <Apple />,
  question: <Question />,
  questionCircle: <QuestionCircle />,
  creditCard: <CreditCard />,
  bigCreditCard: <BigCreditCard />,
  fi: <Fi />,
  en: <En />,
  help: <Help />,
  phone: <Phone />,
  calendar: <Calendar />,
  screenmodeSystem: <ScreenmodeSystem />,
  circle: <Circle />,
  wrench: <Wrench />,
  minimize: <Minimize />,
  maximize: <Maximize />,
  hideComments: <HideComments />,
  close: <Close />,
  expand: <Expand />,
  list: <List />,
  plus: <Plus />,
  templateDocument: <TemplateDocument />,
  text: <Text />,
  file: <File />,
  tickboxCheckFilled: <TickboxCheckFilled />,
  table: <Table />,
  bigSignature: <BigSignature />,
  anchor: <Anchor />,
  chevronBigDown: <ChevronBigDown />,
  preview: <Preview />,
  infoCircle: <InfoCircle />,
  filter: <Filter />,
  templateFolder: <TemplateFolder />,
  sort2: <Sort2 />,
  chevronBigUp: <ChevronBigUp />,
  modalOpen: <ModalOpen />,
  minus: <Minus />,
  camera: <Camera />,
  robot: <Robot />,
  company: <Company />,
  refresh: <Refresh />,
  undo: <Undo />,
  lock: <Lock/>,
  lockOpen: <LockOpen/>,
  documentLocked: <DocumentLocked/>,
  favorite: <Favorite />,
  vector: <Vector />,
  star: <Star />,
  checkCircle: <CheckCircle />
}

const Icon = props => {
  const size = props.size || 6;
  return (
    <span
      className={
        `icon-svg-wrapper ${props.className} inline-block h-${size} w-${size}`
      }
      onClick={props.onClick}
      ref={props.refProp}
      data-bs-dismiss={props.dataBsDismiss}
      style={props.style}
    >
      {icons[props.name]}
    </span>
  );
}

export default (Icon);
