import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { toggleModalFade } from '../../utils/HelperFunctions';

import documentAPI from './documentAPI';
import saveQueueManager from './sliceHelpers/saveQueueManager';

import { calcField } from './nodes/table/CalculationFunctions';

import { collapseHelpers } from './sliceHelpers/collapseHelpers';

import KPConfig from '../../KPConfig.js'
import { sanitizeFilenameInUrl } from '../../utils/StringSanitizer';
import { getGlobalAbortController } from '../../utils/abortController';
import { marked } from 'marked';
import { formatAISuggestionText } from '../../utils/HelperFunctions';

const defaultPrintSettings = {
  pagebreakOnSection: false,
  printCoverPage: false,
  printNamesAndTimestamps: true,
  printSignature: false,
  printTableOfContents: false,
  hideTemplateName: false,
  printMarkingsAndFloorPlan: true
};

const nodeTypesWithNodes = {
  'section': true,
  'macro-section': true,
  'field-set': true,
  'input-notes': true,
  'input-images': true,
  'input-files': true,
  'doc-table': true,
}

const initialState = {
  data: null, // raw json object (nested node structure from mongo)

  // When updating nodes:
  //  - Get the node from state.data with recursiveFindNode(..)
  //  - Update the node. (State.data needs to be kept in sync with the real mongo json data.)
  //  - Reconstruct node data for rendering with generateFlattedNodeStructures(..) or with the partial one
  // (The 'real' data is in state.data, nodeIds and nodesById are generated from state.data with the generateFlattedNodeStructures(..))

  // Helper structures for rendering nodes as a flat list
  nodeIds: [],
  visibleNodeIds: [], // the not collapsed ones
  firstVisibleNodeId: null,
  lastVisibleNodeId: null,
  nodesById: {},

  // TODO: should we use this, or show 'saving' and other statuses with the data in save queue
  status: undefined, //This is set to undefined to prevent unnecessary renders

  // Section move related
  movedNodeId: null,
  // when this is set, ui will scroll to this node (use index from visibleNodeIds list)
  scrollToIndex: null,

  // topNodeIndex tells the index of the topmost node
  // It's needed for showing the sticky section header bar
  topNodeIndex: null,

  // Data related to adding new files/images (before starting to 'save' them)
  newFilesNodeId: null, // The id of the node, from which the files/images are added
  newFilesByFileId: {},

  lightboxImageNodeId: null,
  imageStatus: 'idle',
  documentImagesByNodeId: {},
  signatureNodeId: null,
  shownSignatureNodeModalName: null,
  userSignatureNodeId: null, //This is set when the user checks pre-saved signature checkbox in SignatureModal

  // Move image related
  imageMove: { 
    draggedImage: {},
    imageBeingDragged: false
  },
  imageMoveStatus: 'idle',

  // Text areas
  editingTextNodeId: null,
  textFieldStates: {},

  debouncingNodes: {},  // In this variable we see edited nodes which aren't yet in saveQueue

  // ****************************************************************************************************
  // * SAVE QUEUE
  // ****************************************************************************************************
  nextSaveAttemptTs: 0,
  saveQueueTasksByNodeId: {}, // Base structure to store save tasks

  // We need a quick way to see/count which nodes are being saved or had failed
  //   Structure is {'nodeId': true, 'anotherId': true}
  //   (We're not using an array, because it's easier to set and delete items from a hash.)
  saveQueueSavingNodeIds: {},
  saveQueueFailedNodeIds: {},
  saveQueuePermanentlyFailedNodeIds: {},

  // Save bar visibility state
  saveBarVisible: false,

  // Something to make showing "Saved to server 12/12" possible
  totalSaveQueueTasksInOneCycle: 0,

  // Error notification states
  networkErrorShown: false,
  // ****************************************************************************************************
  addRowStatus: 'idle',
  tableNodeForAddRowModal: null,
  editingRowNodeId: null,
  editingRowColumnIndex: null,
  tableNodeForAddRowModalErrors: null,
  tableRowErrors: null,
  updatedRowsByNodeId : {},


  downloadDocumentStatus: 'idle',
  documentPDFUrl: "",
  documentFormatLabel: "PDF",
  currentPrintSettings: defaultPrintSettings,
  savePrintSettingsStatus: 'idle',

  followStatus: 'idle',
  followers: null,
  followEnabled: null,
  geolocationEnabled: false,
  geolocation: null,

  newRepeatingSectionModalNode: null,
  // There could be different status for all macro-sections, but for simplicity, just use 1. At least its better than 0.
  addRepeatingSectionStatus: 'idle',
  // When you add stuff here, add it also to resetState
  // , if it needs to be reset when document changes.

  // Scrolling to last modified node related
  lastModifiedNode: {
    id: null,
    closestNodeIdInNodeIds: null, // if the id is not in nodeIds list, this will have the closest parent, which is there
    ts: 0
  },
  scrollingToLastModified: false,

  // Tool for forcing rendering the react-list
  requestListRenderTs: 0,

  visibleMarkingNodeIds: [],
  isTemplatePreview: false,

  // Suggestions:
  suggestionNodeId: null,
  suggestionStatus: {},
  suggestionText: {},
  acceptedSuggestion: {},
  suggestionHistory: {},

  //Required nodes related
  requiredIdKeyMapping: {},
  showRequiredNodeHighlight: false,
  scrollingToFirstRequiredUnfilledNode: false,
};

const resetState = (state) => {
  state.data = null;

  delete state.documentId;

  state.nodeIds = [];
  state.visibleNodeIds = [];
  state.firstVisibleNodeId = null;
  state.lastVisibleNodeId = null;
  state.nodesById = {};
  state.status = undefined;

  state.movedNodeId = null;

  state.scrollToIndex = null;
  state.topNodeIndex = null;

  state.newFilesNodeId = null;
  state.newFilesByFileId = {};

  state.lightboxImageNodeId = null;
  state.documentImagesByNodeId = {};
  state.imageStatus = 'idle';

  state.imageMoveStatus = 'idle';
  state.imageMove = {
    draggedImage: {},
    imageBeingDragged: false
  };

  state.signatureNodeId = null;
  state.shownSignatureNodeModalName = null;
  state.userSignatureNodeId = null;

  state.editingTextNodeId = null;
  state.textFieldStates = {};
  state.debouncingNodes = {};

  state.nextSaveAttemptTs = 0;
  state.saveQueueTasksByNodeId = {};
  state.saveQueueSavingNodeIds = {};
  state.saveQueueFailedNodeIds = {};
  state.saveQueuePermanentlyFailedNodeIds = {};
  state.networkErrorShown = false;
  state.saveBarVisible = false;
  state.totalSaveQueueTasksInOneCycle = 0;

  state.tableNodeForAddRowModal = null;
  state.editingRowNodeId = null;
  state.editingRowColumnIndex = null;
  state.rowStates = {};

  state.downloadDocumentStatus = 'idle';
  state.currentPrintSettings = defaultPrintSettings;
  state.savePrintSettingsStatus = 'idle';

  state.followStatus = 'idle';
  state.followers = null;
  state.followEnabled = null;
  state.geolocationEnabled = false;
  state.geolocation = null;

  state.newRepeatingSectionModalNode = null;
  // There could be different status for all macro-sections, but for simplicity, just use 1. At least its better than 0.
  state.addRepeatingSectionStatus = 'idle';

  state.lastModifiedNode = {
    id: null,
    closestNodeIdInNodeIds: null,
    ts: 0
  };
  state.scrollingToLastModified = false;

  state.requestListRenderTs = 0;

  // Don't reset this. We fetch and reset document in floor plan multiple times but we want to control this manually
  // state.visibleMarkingNodeIds = [];
  state.isTemplatePreview = false;

  state.suggestionNodeId = null;
  state.suggestionStatus = {};
  state.suggestionText = {};
  state.acceptedSuggestion = {};
  state.suggestionHistory = {};

  state.requiredIdKeyMapping = {};
  state.showRequiredNodeHighlight = false;
  state.scrollingToFirstRequiredUnfilledNode = false;
};

export const fetchDocument = createAsyncThunk(
  'document/fetch',
  async (params, { dispatch }) => {
    const response = await documentAPI.fetchDocument(params);
    let oid = response?.body?.object_id
    // journal fetch doesn't return value for object id
    if (oid) {
      dispatch(fetchFollowers(oid));  // document is only fetched in Document. We always want to fetch followers after that.
    }
    return response?.body;
  }
);

export const deleteDocument = createAsyncThunk (
  'document/deleteDocument',
  async (params) => {
    const response = await documentAPI.deleteDocument(params)
    return response?.body
  }
);

export const markReady = createAsyncThunk(
  'document/markReady',
  async (documentId) => {
    const response = await documentAPI.markReady(documentId);
    return response?.body;
  }
);

export const updateDocumentDate = createAsyncThunk(
  'document/updateDocumentDate',
  async (opts) => {
    const response = await documentAPI.updateDocumentDate(opts.documentId, opts.date);
    return response?.body;
  }
);

export const downloadDocument = createAsyncThunk(
  'document/downloadDocument',
  async (opts, { getState }) => {
    /**
       *  response1 contains status_id in normal pdf case.
       *  It contains download_url in aiddocs-case.
       */
    

    let url = '';
    const ts = (getState().document.data.date_ts || getState().document.data.created_ts) * 1000;
    const formattedDate = new Intl.DateTimeFormat('fi-FI', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(ts).replaceAll('.', '-');
    
    const documentFormat = opts.format ? opts.format : "pdf";
    const documentName = sanitizeFilenameInUrl(`${opts.documentId} ${opts.objectTitle} ${formattedDate}.${documentFormat}`);

    const globalAbortController = getGlobalAbortController();

    const response1 = await documentAPI.getDocumentStatusId(opts.documentId, opts.type, opts.format, globalAbortController);

    if (response1.statusCode !== 200) {
      throw new Error('Error getting document status');
    }

    const { status_id, download_url } = response1.body || {};

    if (opts.type === 'pdf') {

      if (!status_id) {
        throw new Error('No status ID for PDF document');
      }

      const processedDownloadId = await documentAPI.getPdfProcessedDownloadId(status_id, globalAbortController);
      
      
      //We don't want to throw error if user has canceled the download
      if (processedDownloadId === "AbortError") {
        return;
      }

      if (!processedDownloadId) {
        throw new Error('No processedDownloadId for PDF document');
      }

      url = `${KPConfig.backendUrl}/pdf/download/${processedDownloadId}/${documentName}`;
    } else if (opts.type === 'aiddocs') {

      if (!download_url) {
        throw new Error('No download URL for aiddocs document');
      }

      url = download_url;
    }
    return url;
  }
);

export const lockDocument = createAsyncThunk(
  'document/lockDocument',
  async (documentId) => {
    const response = await documentAPI.lockDocument(documentId);
    return response?.body;
  }
);

export const unlockDocument = createAsyncThunk(
  'document/unlockDocument',
  async (documentId) => {
    const response = await documentAPI.unlockDocument(documentId);
    return response?.body;
  }
);

export const savePrintSettings = createAsyncThunk(
  'document/savePrintSettings',
  async (_opts, { getState }) => {
    const response = await documentAPI.savePrintSettings(getState().document.documentId, getState().document.currentPrintSettings);

    return response.body;
  }
);

// Sections
export const moveSection = createAsyncThunk(
  'document/moveSection',
  async (opts) => {
    const isSubSection = opts.parentNodeId ? true : false;
    const response = await documentAPI.moveSection(opts.documentId, opts.parentNodeId, isSubSection, opts.fromIndex, opts.toIndex);
    return response?.body;
  }
);
export const updateSectionPageBreak = createAsyncThunk(
  'document/updateSectionPageBreak',
  async (opts) => {
    const response = await documentAPI.updateSectionPageBreak(opts.documentId, opts.nodeId, opts.pageBreak);
    return response?.body;
  }
)
export const addRepeatingSection = createAsyncThunk(
  'document/addRepeatingSection',
  async (opts) => {
    const response = await documentAPI.addRepeatingSection(opts.documentId, opts.nodeId, opts.macroId, opts.copyOption, opts.templateNodeId);
    return response?.body;
  }
);
export const deleteSection = createAsyncThunk(
  'document/deleteSection',
  async (opts) => {
    const response = await documentAPI.deleteSection(opts.documentId, opts.nodeId);
    return response?.body;
  }
);
export const updateSection = createAsyncThunk(
  'document/updateSection',
  async (opts) => {
    const response = await documentAPI.updateSection(opts.documentId, opts.nodeId, opts.data);
    return response?.body;
  }
)

export const uploadFile = createAsyncThunk(
  'document/uploadFile',
  async (opts, { getState }) => {
    // This is run after the pending reducer

    const nodes = opts.documentSaveCtx?.nodes;
    const node = nodes[opts.nodeId];
    if (!node) throw new Error(400); // 400 makes this error a "permanent" one

    const file = node.files[opts.fileId];
    if (!file) throw new Error(400); // 400 makes this error a "permanent" one

    if (file.isImage) {
      const location = getState().document.geolocationEnabled && getState().document.geolocation ? getState().document.geolocation : null;
      const response = await documentAPI.uploadImage(opts.documentId, opts.nodeId, file.data, file.index, (file.description || ""), location);
      return response?.body;
    } else {
      const response = await documentAPI.uploadFile(
        opts.documentId, 
        opts.nodeId, 
        file.data, 
        file.index, 
        file.name, 
        (file.description || ""),
        file.isPublic
      );
      return response?.body;
    }
  },
  { condition: (opts, { getState }) => {
    // This will be run before the pending reducer

    // Get the saving state and stop processing if image upload is already running
    const saving = getState()?.document?.saveQueueTasksByNodeId?.[opts?.nodeId]?.tasksById?.[opts.fileId]?.status?.saving === true;
    if (saving) console.log("thunk condition: "+opts.fileId+" was being saved, skip");

    return !saving; // When not saving, let continue
  }}
);

export const askAIImageTitle = createAsyncThunk(
  'document/askAIImageTitle',
  async (opts, { getState }) => {
    const nodes = opts.documentSaveCtx ? opts.documentSaveCtx?.nodes : getState().document.nodesById;
    const node = nodes[opts.nodeId];
    if (!node) throw new Error(400); // 400 makes this error a "permanent" one

    const file = opts.fileId ? node.files[opts.fileId] : null;

    const response = await documentAPI.askAIImageTitle(opts.documentId, opts.nodeId, file?.data, opts.instructions);
    return response?.body;
  }
);

export const updateFile = createAsyncThunk(
  'document/updateFile',
  async (opts) => {

    const { documentId, nodeId, title, public: isPublic } = opts;
    const response = await documentAPI.updateFile(
      documentId, 
      nodeId, 
      title, 
      isPublic
    );
    return response?.body;
  }
);

export const deleteFile = createAsyncThunk(
  'document/deleteFile',
  async (opts) => {
    const response = await documentAPI.deleteFile(opts.documentId, opts.nodeId);
    return response?.body;
  }
);

export const updateCheckbox = createAsyncThunk(
  'document/updateCheckbox',
  async (opts) => {
    const response = await documentAPI.updateCheckbox(opts.documentId, opts.nodeId, opts.isChecked);
    return response?.body;
  }
);

export const updateDateTime = createAsyncThunk(
  'document/updateDateTime',
  async (opts) => {
    const response = await documentAPI.updateDateTime(opts.documentId, opts.nodeId, opts.datetime);
    return response?.body;
  }
);

export const updateSelectionListNode = createAsyncThunk(
  'document/updateSelectionListNode',
  async (opts) => {
    const response = await documentAPI.updateSelectionListNode(opts.documentId, opts.nodeId, opts.selected);
    return response?.body;
  }
);

export const updateTableRow = createAsyncThunk(
  'document/updateTableRow',
  async (opts) => {
    const response = await documentAPI.updateTableRow(opts.documentId, opts.nodeId, opts.values);
    return response?.body;
  }
);

export const saveSignature = createAsyncThunk(
  'document/saveSignature',
  async (opts) => {
    const response = await documentAPI.saveSignature(opts.documentId, opts.nodeId, opts.signature);
    return response?.body;
  }
);

export const setSigner = createAsyncThunk(
  'document/setSigner', 
  async(opts) => {
    const response = await documentAPI.setSigner(opts.documentId, opts.nodeId, opts.userId);
    return response?.body;
  }
);

// We actually update node-data here.
// TODO: We could use saveQueue
export const updateImage = createAsyncThunk(
  'document/updateImage',
  async (params) => {
    const response = await documentAPI.updateImage(params);
    return response.body;
  }
);

export const rotateImage = createAsyncThunk(
  'document/rotateImage',
  async (params) => {
    const response = await documentAPI.rotateImage(params);
    return response.body;
  }
);

export const deleteImage = createAsyncThunk(
  'document/deleteImage',
  async (params) => {
    const response = await documentAPI.deleteImage(params);
    return response.body;
  }
);

export const moveImage = createAsyncThunk(
  'document/moveImage',
  async (opts) => {
    const response = await documentAPI.moveImage(opts.documentId, opts.parentNodeId, opts.fromIndex, opts.toIndex);
    return response?.body;
  }
);

export const moveImageToSection = createAsyncThunk(
  'document/moveImageToSection',
  async (opts, { getState }) => {
    const originalImageId = opts.originalNodeId;
    const data = getState().document.data;
    const node = recursiveFindNode(data, originalImageId);

    const response = await documentAPI.moveImageToSection(opts.documentId, opts.newParentNodeId, originalImageId, node);
    return response?.body;
  }
);

export const updateCoverImage = createAsyncThunk(
  'document/updateCoverImage',
  async (params) => {
    const response = await documentAPI.updateCoverImage(params);
    return response.body;
  }
);

// Text field related
export const askAISuggestion = createAsyncThunk(
  'document/askAISuggestion',
  async (params, { getState }) => {
    params.documentId = getState().document.documentId;
    const response = await documentAPI.askAISuggestion(params);
    return response.body;
  }
);

export const updateText = createAsyncThunk(
  'document/updateText',
  async (params) => {
    const response = await documentAPI.updateText(params.documentId, params.nodeId, params.nodeType, params.text, params.format);
    return response?.body;
  }
);
export const addNote = createAsyncThunk(
  'document/addNote',
  async (params) => {
    const response = await documentAPI.addNote(params.documentId, params.nodeId, params.text, params.format);
    return response?.body;
  }
);
export const deleteNote = createAsyncThunk(
  'document/deleteNote',
  async (params) => {
    const response = await documentAPI.deleteNote(params.documentId, params.nodeId);
    return response?.body;
  }
);

export const addTableRowFromAddRowModal = createAsyncThunk(
  'document/addTableRow',
  async (params, {getState}) => {
    const values = getState().document.tableNodeForAddRowModal.values;
    const nodeId = getState().document.tableNodeForAddRowModal.id;

    const response = await documentAPI.addTableRow(params.documentId, nodeId, values);
    return response?.body;
  }
);

export const deleteTableRow = createAsyncThunk(
  'document/deleteTableRow',
  async (params) => {
    const response = await documentAPI.deleteTableRow(params.documentId, params.nodeId);
    return response?.body;
  }
);

export const follow = createAsyncThunk(
  'document/follow',
  async (params, { dispatch }) => {
    const response = await documentAPI.follow(params.objectId);
    dispatch(fetchFollowers(params.objectId));
    return response?.body;
  }
);

export const unfollow = createAsyncThunk(
  'document/unfollow',
  async (params, { dispatch }) => {
    const response = await documentAPI.unfollow(params.objectId);
    dispatch(fetchFollowers(params.objectId));
    return response?.body;
  }
);


export const fetchFollowers = createAsyncThunk(
  'document/fetchFollowers',
  async (params) => {
    const response = await documentAPI.fetchFollowers(params);
    return response?.body;
  }
);

export const sendNotification = createAsyncThunk(
  'document/sendNotification',
  async (params) => {
    const response = await documentAPI.sendNotification(params.documentId, params.recipients, params.message, params.includePdf);
    return response?.body;
  }
);

export const sendDocumentPdfLink = createAsyncThunk(
  'document/sendDocumentPdfLink',
  async (params) => {
    let response = await documentAPI.sendDocumentPdfLink(params.documentId, params.recipientId);
    return response.body;
  }
);

// Flatten nodes for list a like browsing
// This is the 'old' processNodesForRendering(..).
// Use this when nodeIds are changed (nodes were added, removed or their order was changed)
// Read next function call comment too...
export const generateFlattedNodeStructures = (state) => {
  let nodeIds = [];
  let nodesById = {};

  if (state.data.id) {
    // Save the original document_id (once)
    if (state.documentId === undefined) {
      state.documentId = state.data.id;
    }
    // Reset parent node id to be something other than document_id.
    state.data.id = 0;
  }

  flattenNodeAsParentRecursively(
    state, nodeIds, nodesById,
    state.data, // first parent
    {level: 1}
  );

  state.nodeIds = nodeIds;
  state.nodesById = nodesById;

  collapseHelpers.updateVisibleNodeIds(state);

  // Special handling to scroll to the moved node after we know its index in visibleNodeIds list
  if (state.movedNodeId !== null) {
    state.nodesById[state.movedNodeId].wasMoved = true;
    state.scrollToIndex = state.visibleNodeIds.indexOf(state.movedNodeId);
    console.debug("setting scrollToIndex to "+state.scrollToIndex);
    state.movedNodeId = null;
  }
};

// Use this when only one node (and possibly its children) is/are changed.
const generateFlattedNodeStructuresPartially = (state, node) => {
  flattenNodeRecursively(
    state, state.nodeIds, state.nodesById,
    state.nodesById[state.nodesById[node.id].parentNodeId],
    node,
    null, // nodeSectionIndex will be 'copied' from existing node
    null, // levelIndex will be 'copied' from existing node
    {level: state.nodesById[node.id].nodeLevel, partial: true}
  );

  collapseHelpers.updateVisibleNodeIds(state);
};

const flattenNodeAsParentRecursively = (state, nodeIds, nodesById, parent, opts) => {
  if (!parent || !parent.nodes) return;

  // Own index counter for each level.
  let sectionIndex = -1; // Start from -1, because it will be incremented before first usage
  let levelIndex = -1; // Sub index for each level

  for (let node of parent.nodes) {
    if (!node || !node.type) continue; // Ship ghost nodes

    if (nodeIds.length && node.pageBreak === 'pageBreakBefore') {
      // tell previous node that one should have bottom border
      nodesById[nodeIds[nodeIds.length-1]].hasPageBreakAfter = true;
      // (don't overwrite node.pageBreak, because the prev node could have also pageBreakBefore)
    }

    let nodeSectionIndex;
    if (node.type === 'section' || node.type === 'macro-section') {
      sectionIndex++;
      nodeSectionIndex = sectionIndex;
    }

    // Increment node index 'inside one parent'
    levelIndex++;

    flattenNodeRecursively(state, nodeIds, nodesById, parent, node, nodeSectionIndex, levelIndex, opts);
  }
};

const flattenNodeRecursively = (state, nodeIds, nodesById, parent, node, nodeSectionIndex, levelIndex, opts) => {
  const prevNodeState = state?.nodesById[node.id];

  if (opts.partial && nodesById?.[node?.id]) {
    // When updating partially and the nodesById[node] exists
    nodesById[node.id] = {
      ...prevNodeState, // preserve previous values
      ...node,          // update new ones
      // The rest is 'almost' same as without partial update
      nodes: undefined, // remove 'following' nodes from the flat item
      childNodes: [],
      parentNodeId: parent?.id || 0, // node must have some sane parentNodeId, use 0 if parent does not exist
      // nodeIndex: preseve
      nodeLevel: opts.level,
      collapsed: prevNodeState ? prevNodeState.collapsed : 0,
      sectionIndex: nodeSectionIndex !== undefined && nodeSectionIndex !== null ? nodeSectionIndex : prevNodeState?.sectionIndex,
      levelIndex: levelIndex !== undefined && levelIndex !== null ? levelIndex : prevNodeState?.levelIndex,
      // isMacroSection: preserve
    };
  } else {
    nodesById[node.id] = {
      ...node,
      nodes: undefined, // remove 'following' nodes from the flat item
      childNodes: [],
      parentNodeId: parent?.id || 0, // node must have some sane parentNodeId, use 0 if parent does not exist
      nodeIndex: nodeIds.length,
      nodeLevel: opts.level,
      collapsed: prevNodeState ? prevNodeState.collapsed : 0,
      sectionIndex: nodeSectionIndex !== undefined && nodeSectionIndex !== null ? nodeSectionIndex : prevNodeState?.sectionIndex,
      levelIndex: levelIndex !== undefined && levelIndex !== null ? levelIndex : prevNodeState?.levelIndex,
      isMacroSection: opts.isMacroSection,
    };
  }

  // Skip some inner node types which are handled in parent node
  if (node.type !== 'macro-section' && // don't add macro-section yet
      node.type !== 'image' &&
      node.type !== 'file' &&
      node.type !== 'note' &&
      node.type !== 'table-row' &&
      !opts.partial // Don't touch nodeIds when processing nodes partially
  ) {
    nodeIds.push(node.id);
  }

  if (parent && parent.id && nodesById[parent.id]) {
    if (
      // update childNodes always when not processing partially
      !opts.partial ||
      // or when processing partially and this node is not in its parent childNodes
      !nodesById[parent.id].childNodes.includes(node.id)
    ) {
      nodesById[parent.id].childNodes.push(node.id);
    }
  }

  if (
    node.nodes &&
    node.type && // we are past the root node here, so we may require the type
    nodeTypesWithNodes[node.type]
  ) {
    // Dig deeper
    let nextIsMacroSection = (node.type === "macro-section") ? node.id : undefined;
    flattenNodeAsParentRecursively(
      state,
      nodeIds,
      nodesById,
      node,
      {
        ...opts, // it's important to pass all existing opts further (especially 'partial')
        level: opts.level+1,
        isMacroSection: nextIsMacroSection
      }
    );
  }

  // ... At this phase child nodes are processed ...

  // Replace/add macro-section node after it's contents,
  // because it's easier to add the "add new" button at the bottom of the section.
  if (
    !opts.partial && // Don't touch nodeIds when processing nodes partially
    node.type === 'macro-section'
  ) {
    nodeIds.push(node.id);
  }

  // Generate grouping information for file nodes
  if (node.type === 'input-files') {
    nodesById[node.id].fileGroupsByFilename = {};
    nodesById[node.id].fileGroups = [];
    for (const childNodeId of nodesById[node.id].childNodes) {
      const childNode = nodesById[childNodeId];
      const filename = childNode.filename;
      const created_ts = childNode.created_ts;
      const isPublic = childNode.public || false;

      if (!nodesById[node.id].fileGroupsByFilename[filename]) {
        nodesById[node.id].fileGroupsByFilename[filename] = {name: filename, files: [], collapsed: true};
        nodesById[node.id].fileGroups.push(filename);
      }
      nodesById[node.id].fileGroupsByFilename[filename].files.push(
        {parentNodeId: node.id, 
          nodeId: childNodeId, 
          filename: filename, 
          createdTs: created_ts,
          public: isPublic
        })
    }
    // Sort node.fileGroupsByFilename[filename].files by created_ts for every filename
    for (const filename in nodesById[node.id].fileGroupsByFilename) {
      nodesById[node.id].fileGroupsByFilename[filename].files.sort( (a,b) => {
        if (a.createdTs < b.createdTs) {
          return 1;
        } else {
          return -1;
        }
      });
    }
  }

  // Keep track of last modified node
  if (node.type) {
    let ts = 0;
    // Find the newest create/modify timestamp inside node
    if (node.created_ts > ts) ts = node.created_ts;
    if (node.modified_ts > ts) ts = node.modified_ts;
    if (node?.value?.created_ts > ts) ts = node.value.created_ts;
    if (node?.value?.modified_ts > ts) ts = node.value.modified_ts;
    // Check is it later than the stored one
    if (ts > state.lastModifiedNode.ts) {
      // Find parent node, which is in the nodeList.
      // Those are the ones, which can be scrolled into.
      let parentIdInNodeList = null;
      let idToCheck = node.id;
      while (idToCheck && !parentIdInNodeList) {
        if (nodeIds.includes(idToCheck)) {
          parentIdInNodeList = idToCheck;
        }
        idToCheck = nodesById[idToCheck].parentNodeId;
      }
      // Store the new latest one
      state.lastModifiedNode = {
        id: node.id,
        closestNodeIdInNodeIds: parentIdInNodeList,
        ts: ts
      };
      //console.debug("state.lastModifiedNode = "+JSON.stringify(state.lastModifiedNode));
    }

    if (node.type === 'image') {
      state.documentImagesByNodeId[node.id] = node;
    }

    if (node.required) {
      state.requiredIdKeyMapping[node.id] = node.key;
    }
  }
};

export const recursiveFindNode = (data, nodeId) => {

  //If you write to a text-type node and leave before saving, the data will be null.
  if (!data) return;

  if (data.id === nodeId) return data;

  if (!data.nodes) return null;

  for (let node of data.nodes) {
    const found = recursiveFindNode(node, nodeId);
    if (found) return found;
  }

  return null;
};

const removeRequiredNodes = (node, state) => {
  if (!node.nodes) return;

  for (const childNode of node.nodes) {
    if (node.type === "section" && childNode.required && state.requiredIdKeyMapping[childNode.id]) {
      delete state.requiredIdKeyMapping[childNode.id];
    }
    removeRequiredNodes(childNode, state);
  }
}

export const isNodeValueEmpty = (node) => {
  if (!node) return true;

  const { type, value, childNodes } = node;

  switch (type) {
    case "input-checkbox":
      return !value || !value.isChecked;
    case "input-notes":
    case "input-images":
    case "input-files":
    case "doc-table":
    case "macro-section":
      return !childNodes || childNodes.length === 0;
    case "input-textfield":
    case "input-textarea":
      return !value || value.text === "";
    case "input-select":
      return !value || value.selected === "";
    case "input-signature":
      return !value || value.filename === "";
    case "input-datetime":
      return !value || value.datetime == null;
    default:
      return false;
  }
};

export const getFirstUnfilledRequiredNode = (document) => {
  const { requiredIdKeyMapping, nodesById } = document;

  for (const nodeId in requiredIdKeyMapping) {
    if (nodesById[nodeId] && isNodeValueEmpty(nodesById[nodeId])) {
      return nodesById[nodeId];
    }
  }

  return null;
}

const handleNodePending = (state, action) => {

  const nodeId = action?.meta?.arg.nodeId;
  const taskId = action?.meta?.arg.taskId;

  if (!nodeId) return;

  // Update task status
  saveQueueManager.updateTaskStatus(state, nodeId, taskId, {saving: true});
}

const handleNodeRejected = (state, action) => {
  const nodeId = action?.meta?.arg.nodeId;
  const taskId = action?.meta?.arg.taskId;

  //console.debug("action => "+JSON.stringify(action));

  if (!nodeId) return;

  const retryableErrorCodes = {
    "-1": true,  // exception on network error
    "408": true, // timeout
  };
  let isErrorRetryable = retryableErrorCodes[String(action?.error?.message || "")] === true;

  // Handle possible 403
  if (action?.error?.message === "403") {
    state.permissionDenied = true;
  }

  saveQueueManager.updateTaskStatus(
    state,
    nodeId,
    taskId,
    {
      saving: false,
      failed: isErrorRetryable,
      permanentlyFailed: !isErrorRetryable
    }
  );
}

const handleNodeFulfilled = (state, action) => {
  const nodeId = action?.meta?.arg.nodeId;

  if (!nodeId) return;

  // Find the node which is goind to be updated
  const node = recursiveFindNode(state.data, nodeId);

  // Setting node.value = action.payload wont work, because
  // , server returns value keys prefixed with 'value.'
  // So strip 'value.' from keys and update the changes

  // EDIT: Except with table-row, then it returns values without prefix.
  // Why???
  if (node.type === 'table-row') {
    node.values = action.payload.values;
  } else {
    let strippedValue = {};
    for (const dotK in action.payload) {
      const split = dotK.split('.');
      if (split.length === 2 && split[0] === 'value') {
        strippedValue[split[1]] = action.payload[dotK];
      }
    }
    node.value = {
      ...node.value,
      ...strippedValue
    };
  }

  // Reset completed state on modify
  state.data.completed = false;

  // Reconstruct node data for rendering
  generateFlattedNodeStructuresPartially(state, node);

  // Remove from save queue
  const taskId = action?.meta?.arg.taskId;
  saveQueueManager.removeFromSaveQueue(state, nodeId, taskId);
}

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  // (these are used for modifying the state without external api calls)
  reducers: {
    reset(state) {
      resetState(state);
    },
    // Collapse/expand node
    collapseNode(state, action) {
      //console.debug("action = "+JSON.stringify(action));
      if (!state.nodesById) {
        //console.debug("!state.nodesById");
        return;
      }
      const node = state.nodesById[action.payload];
      if (!node) {
        //console.debug("!node");
        return;
      }
      node.collapsed = 1;
      collapseHelpers.collapseNode(state, node);
      collapseHelpers.updateVisibleNodeIds(state);
    },
    expandNode(state, action) {
      //console.debug("action = "+JSON.stringify(action));
      if (!state.nodesById) {
        //console.debug("!state.nodesById");
        return;
      }
      const node = state.nodesById[action.payload];
      if (!node) {
        //console.debug("!node");
        return;
      }
      node.collapsed = 0;
      collapseHelpers.expandNode(state, node);
      collapseHelpers.updateVisibleNodeIds(state);
    },
    collapseAllSectionNodes(state) {
      collapseHelpers.collapseAllSectionNodes(state);
      collapseHelpers.updateVisibleNodeIds(state);

      state.requestListRenderTs = Date.now();
    },
    expandAllSectionNodes(state) {
      collapseHelpers.expandAllSectionNodes(state);
      collapseHelpers.updateVisibleNodeIds(state);

      state.requestListRenderTs = Date.now();
    },

    resetMovedSection(state, action) {
      if (!state.nodesById) {
        //console.debug("!state.nodesById");
        return;
      }
      const node = state.nodesById[action.payload];
      if (!node) {
        //console.debug("!node");
        return;
      }

      // Reset wasMoved
      if (node.wasMoved !== undefined) {
        node.wasMoved = undefined;
      }
    },
    togglePageBreakMenu(state, action) {
      if (!state.nodesById) {
        return;
      }
      const node = state.nodesById[action.payload];
      if (!node) {
        return;
      }
      node.pageBreakMenuOpen = !node.pageBreakMenuOpen;
    },

    // topNodeIndex tells the index of the topmost node
    // It's needed for showing the sticky section header bar
    setTopNodeIndex(state, action) {
      //console.debug("setTopNodeIndex => "+action.payload);
      state.topNodeIndex = action.payload;
    },

    setSignatureNodeProperties(state, action) {
      state.signatureNodeId = action.payload?.nodeId;
      state.shownSignatureNodeModalName = action.payload?.modalName;
      state.signingRightsId = action.payload?.signingRightsId;
      state.signatureNodeValue = action.payload?.signatureNodeValue;
    },

    // Scrolling to last modified node related
    scrollToLastModifiedNode(state) {
      let lastModifiedNodeIndexInNodeIds = state.visibleNodeIds.indexOf(state.lastModifiedNode.closestNodeIdInNodeIds);
      if (lastModifiedNodeIndexInNodeIds >= 0) {
        state.scrollToIndex = lastModifiedNodeIndexInNodeIds;
        state.scrollingToLastModified = true; // This is needed for the last scroll adjustment. More details in NodeList.js.
      }
    },
    resetScrollingToLastModified(state) {
      state.scrollingToLastModified = false;
    },

    // Scrolling to last and first visible node
    scrollToFirstVisibleIndex(state) {
      let firstVisibleNode = state.visibleNodeIds.indexOf(state.firstVisibleNodeId)
      state.scrollToIndex = firstVisibleNode;
    },
    scrollToLastVisibleIndex(state) {
      let lastVisibleNode = state.visibleNodeIds.indexOf(state.lastVisibleNodeId)
      state.scrollToIndex = lastVisibleNode;
    },

    // This is called after ui has scrolled to scrollToIndex
    resetScrollToIndex(state) {
      state.scrollToIndex = null;
    },

    //Scrolling to first required node
    scrollToFirstRequiredUnfilledNode(state) {
      const firstRequiredUnfilledNode = getFirstUnfilledRequiredNode(state);
      if (firstRequiredUnfilledNode) {
        state.scrollToIndex = state.visibleNodeIds.indexOf(firstRequiredUnfilledNode.id);
        state.scrollingToFirstRequiredUnfilledNode = true;
      }
    },
    resetScrollingToFirstRequiredUnfilledNode(state) {
      state.scrollingToFirstRequiredUnfilledNode = false;
    },

    // Adding files/images related
    setNewFilesNodeId(state, action) {
      state.newFilesNodeId = action.payload;
    },
    addNewFileByFileId(state, action) {
      state.newFilesByFileId[action.payload.id] = {id: action.payload.id, data: action.payload.data};
    },
    removeNewFileByFileId(state, action) {
      if (state.newFilesByFileId[action.payload]) {
        delete state.newFilesByFileId[action.payload]
      }
    },
    markNewFileCompressed(state, action) {
      state.newFilesByFileId[action.payload].compressed = true;
    },
    resetNewFiles(state) {
      state.newFilesNodeId = null;
      state.newFilesByFileId = {};
    },
    addDragImage(state, action) {
      state.imageMove.draggedImage = action.payload;
    },
    setImageBeingDragged(state, action) {
      state.imageMove.imageBeingDragged = action.payload;
    },
    setDragImageOrder(state, action) {
      const parentNodeId = action?.payload.parentNodeId
      const childNodes = state.nodesById[parentNodeId].childNodes
      const copyChildNodes = [...childNodes];

      const from = action?.payload.fromIndex
      const to = action?.payload.toIndex
      const fromNode = copyChildNodes[from];

      copyChildNodes.splice(from, 1);
      copyChildNodes.splice(to, 0, fromNode);

      state.nodesById[parentNodeId].childNodes = copyChildNodes
    },
    resetImageDrag(state) {
      state.imageMove = {
        draggedImage: {},
        imageBeingDragged: false
      };
    },

    setLightboxImageNodeId: (state, action) => {
      state.lightboxImageNodeId = action.payload;
    },

    // Text areas
    acceptSuggestion(state, action) {
      const nodeId = state.suggestionNodeId;
      const format = state.suggestionText[nodeId]?.format;
      let text = action.payload;
      if (format === 'markdown') {
        // Remove markdown escapes from links
        // This is copied from MarkdownEditor
        const customMarkedRenderer = {
          link(href, title, text) {
            const link = (text || "")
              .replace(/\\([_~*`\[\]\\])/g, '$1')
            return link;
          }
        };
        marked.use({renderer: customMarkedRenderer});
        text = marked(text);
      }
      state.acceptedSuggestion[nodeId] = {text, format};
    },
    rejectSuggestion(state) {
      state.suggestionNodeId = null;
      state.suggestionText = {};
      state.acceptedSuggestion = {};
    },
    undoSuggestion(state, action) {
      const nodeId = action.payload.nodeId;
      const fileId = action.payload.fileId;
      if (fileId) {
        state.acceptedSuggestion[nodeId][fileId] = {text: state.suggestionHistory[nodeId]?.[fileId]};
        state.suggestionHistory[nodeId][fileId] = null;
      } else {
        state.acceptedSuggestion[nodeId] = {text: state.suggestionHistory[nodeId]};
        state.suggestionHistory[nodeId] = null;
      }
    },
    setShowRequiredNodeHighlight(state, action) {
      state.showRequiredNodeHighlight = action.payload;
    },
    setEditingTextNodeId(state, action) {
      state.editingTextNodeId = action.payload;
    },
    setTextFieldState(state, action) {
      //console.debug("textFieldStates["+action.payload.nodeId+"] => "+JSON.stringify(action.payload.state));
      state.textFieldStates[action.payload.nodeId] = {
        ...state.textFieldStates[action.payload.nodeId],
        ...action.payload.state
      }
    },
    setDebouncingNode(state, action) {
      state.debouncingNodes[action.payload.nodeId] = true;
    },
    deleteDebouncingNode(state, action) {
      delete state.debouncingNodes[action.payload.nodeId];
    },

    // Files (not images)
    setFileGroupCollapse(state, action) {
      state.nodesById[action.payload.parentNodeId].fileGroupsByFilename[action.payload.filename].collapsed = !!action.payload.collapsed;
    },

    // <SAVE QUEUE>
    addToSaveQueue(state, action) {
      if (!state.nodesById[action.payload.nodeId]) return;

      saveQueueManager.addToSaveQueue(
        state,
        action.payload.nodeId,
        action.payload.taskId,
        action.payload.taskData
      );
    },
    setNextSaveAttemptTs(state, action) {
      state.nextSaveAttemptTs = action.payload;
      console.debug("state.nextSaveAttemptTs = "+state.nextSaveAttemptTs);
    },
    discardTask(state, action) {
      // Remove from save queue
      saveQueueManager.removeFromSaveQueue(state, action.payload.nodeId, action.payload.taskId, {discard: true});
    },
    clearFailedTaskStatuses(state, action) {
      saveQueueManager.clearFailedTaskStatuses(state, action.payload.nodeId);
    },
    setSaveBarVisible(state, action) {
      state.saveBarVisible = action.payload;
    },
    clearZombieTasks(state) {
      let toDel = [];
      for (const nodeId in state.saveQueueTasksByNodeId) {
        if (!state.nodesById[nodeId]) {
          toDel.push(nodeId);
        }
      }
      for (const delId of toDel) {
        saveQueueManager.removeWholeNodeFromSaveQueue(state, delId);
      }
    },
    setNetworkErrorShown(state, action) {
      state.networkErrorShown = action.payload;
    },
    // </SAVE QUEUE>

    setTableNodeForAddRowModal(state, action) {

      state.tableNodeForAddRowModal = {
        ...action.payload.node,
        values: []
      };

      if (action.payload.copyLastRow) {
        const lastRow = state.nodesById[action.payload.node.childNodes[action.payload.node.childNodes.length - 1]];
        state.tableNodeForAddRowModal.values = lastRow.values;
      } else if (action.payload.editTableRow) {
        state.tableNodeForAddRowModal.values = action.payload.node.values;
        state.tableNodeForAddRowModal.columns = action.payload.columns;
        state.tableNodeForAddRowModal.isRowEdit = true;
      } else {
        state.tableNodeForAddRowModal.columns?.forEach(() => {
          state.tableNodeForAddRowModal.values.push('');
        });
      }
    },
    setTableNodeForAddRowModalValue(state, action) {
      state.tableNodeForAddRowModal.values[action.payload.index] = action.payload.value;

      let values = [...state.tableNodeForAddRowModal.values];

      const error = {values: []};
      values.forEach((_v,i) => {
        if (state.tableNodeForAddRowModal.columns?.[i].eq) {
          state.tableNodeForAddRowModal.values[i] = calcField(i, values, 0, state.tableNodeForAddRowModal.columns, error);
        }
      });

      state.tableNodeForAddRowModalErrors = error.values;
    },
    setEditingRowNode(state, action) {
      state.editingRowColumnIndex = action.payload?.index;

      /**
       * Dont initialize column values with old values
       * rowState already has latest values.
       */
      if (action.payload && state.editingRowNodeId !== action.payload.nodeId) {
        state.editingRowNodeId = action.payload?.nodeId;

        if (action.payload) {
          if (!state.rowStates[action.payload.nodeId]) {
            const node = state.nodesById[action.payload.nodeId];
            state.rowStates[action.payload.nodeId] = {
              values: node?.values || []
            };
          }
        }
      }
    },
    setRowState(state, action) {
      if (!state.rowStates[action.payload.nodeId]) {
        state.rowStates[action.payload.nodeId] = {};
      }

      if (action.payload?.state.cursorPointer) {
        state.rowStates[action.payload.nodeId].cursorPointer = action.payload?.state.cursorPointer;
      }
      if (action.payload?.state.values) {
        state.rowStates[action.payload.nodeId].values = action.payload?.state.values;
      }
      if (action.payload?.errors !== undefined) {
        if (!state.tableRowErrors) {
          state.tableRowErrors = {};
        }
        state.tableRowErrors[action.payload.nodeId] = action.payload.errors;
      }
    },
    setCurrentPrintSettings(state) {
      state.currentPrintSettings = state.data.printSettings || defaultPrintSettings;
    },
    setPrintSettingValue(state, action) {
      state.currentPrintSettings[action.payload.key] = action.payload.value;
    },
    setCustomPrintSettingValue(state, action) {
      // I guess when we use customOptions, it always exist. But do this safety set anyway.
      if (!state.currentPrintSettings.customOptions) {
        state.currentPrintSettings.customOptions = {};
      }

      state.currentPrintSettings.customOptions[action.payload.key] = action.payload.value;
    },
    setGeolocationEnabled(state, action) {
      state.geolocationEnabled = action.payload;
    },
    setGeolocation(state, action) {
      state.geolocation = action.payload;
    },
    setNewRepeatingSectionModalNode(state, action) {
      state.newRepeatingSectionModalNode = action.payload;
    },
    setVisibleMarkingNodeIds: (state, action) => {
      state.visibleMarkingNodeIds = action.payload;
      collapseHelpers.updateVisibleNodeIds(state);
    },
    updateLastModifiedNodeTs: (state, action) => {
      // Check is it later than the stored one
      if (action.payload?.ts > state.lastModifiedNode?.ts) {
        // Find parent node, which is in the nodeList.
        // Those are the ones, which can be scrolled into.
        let parentIdInNodeList = null;
        let idToCheck = action.payload.nodeId;
        while (idToCheck && !parentIdInNodeList) {
          if (state.nodeIds?.includes(idToCheck)) {
            parentIdInNodeList = idToCheck;
          }
          idToCheck = state.nodesById[idToCheck].parentNodeId;
        }
        // Store the new latest one
        state.lastModifiedNode = {
          id: action.payload?.nodeId,
          closestNodeIdInNodeIds: parentIdInNodeList,
          ts: action.payload?.ts
        };
      }
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // fetchDocument
      .addCase(fetchDocument.pending, (state) => {
        //console.debug("fetchDocument pending");

        // Get a fresh start
        resetState(state);

        state.status = 'fetchingDocument';
      })
      .addCase(fetchDocument.fulfilled, (state, action) => {
        //console.debug("fetchDocument fulfilled");

        state.status = 'idle';
        state.data = action.payload;
        state.isTemplatePreview = action.meta?.arg?.isTemplatePreview;
        generateFlattedNodeStructures(state);
      })
      .addCase(fetchDocument.rejected, (state, action) => {
        //console.debug("fetchDocument rejected");
        state.status = 'idle';
        state.data = action.payload;
      })

      .addCase(deleteDocument.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteDocument.fulfilled, (state) => {
        state.status = 'idle'
      })
      .addCase(deleteDocument.rejected, (state) => {
        state.status = 'failed'
      })

      // moveSection
      .addCase(moveSection.fulfilled, (state, action) => {
        //console.debug("moveSection fulfilled, action.meta="+JSON.stringify(action.meta));

        // save nodeId for processing it later in generateFlattedNodeStructures(..)
        state.movedNodeId = action?.meta?.arg.nodeId;

        const parentNodeId = action?.meta?.arg.parentNodeId;
        if (parentNodeId) {
          // Find parent node (from the nested data) and update its contents.
          // Api call returns only the nodes which were under the parent.
          const parentNode = recursiveFindNode(state.data, parentNodeId)
          if (parentNode && parentNode.nodes) {
            parentNode.nodes = action.payload.nodes;
          }
        } else {
          // When moving top level sections, the whole nodes structure is returned
          state.data.nodes = action.payload.nodes;
        }

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructures(state);

        state.status = 'idle';
      })
      .addCase(updateSectionPageBreak.fulfilled, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) return;

        // Find node and update it
        let node = recursiveFindNode(state.data, nodeId);
        if (action.payload.pageBreak !== undefined) {
          node.pageBreak = action.payload.pageBreak;
        }

        // Reconstruct node data for rendering
        generateFlattedNodeStructures(state);
      })
      // Add repeating section
      .addCase(addRepeatingSection.pending, (state) => {
        state.addRepeatingSectionStatus = 'saving';
      })
      .addCase(addRepeatingSection.fulfilled, (state, action) => {
        state.addRepeatingSectionStatus = 'idle';
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) {
          console.error("ERROR: addRepeatingSection fulfilled parameter error");
          return;
        }

        // Find the node to update
        const node = recursiveFindNode(state.data, nodeId);

        if (!node.nodes) {
          node.nodes = [];
        }
        // Add new section inside the node
        node.nodes.push(action.payload);

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructures(state);
      })
      // Delete section
      .addCase(deleteSection.fulfilled, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) {
          console.error("ERROR: deleteSection fulfilled parameter error");
          return;
        }

        const parentNodeId = state?.nodesById?.[nodeId]?.parentNodeId;
        if (!parentNodeId) return;

        // Find the parent of the deleted node
        const node = recursiveFindNode(state.data, parentNodeId);

        const hasRequiredNodes = Object.keys(state.requiredIdKeyMapping).length > 0;

        if (hasRequiredNodes) {
          removeRequiredNodes(node, state);
        }

        // Recreate child nodes (skip the deleted one)
        let updatedChildNodes = [];
        for (const childNode of node.nodes) {
          if (childNode.id !== nodeId) {
            updatedChildNodes.push(childNode);
          }
        }
        node.nodes = updatedChildNodes;

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructures(state);

        // Make sure we have the node deleted also from the secondary data structure
        if (state.nodesById[nodeId]) {
          delete state.nodesById[nodeId];
        }
      })
      // Update section (title + everything except pageBreak)
      .addCase(updateSection.pending, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;
        const taskId = action?.meta?.arg.taskId;

        if (!nodeId) return;

        // Update task status
        saveQueueManager.updateTaskStatus(state, nodeId, taskId, {saving: true});
      })
      .addCase(updateSection.rejected, (state, action) => {
        handleNodeRejected(state, action);
      })
      .addCase(updateSection.fulfilled, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) return;

        // Find the node and update it
        const node = recursiveFindNode(state.data, nodeId);
        node.title = action.payload.title;
        // TODO: add updating new keys here (except pageBreak, it has its own reducer)

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);

        // Remove from save queue
        const taskId = action?.meta?.arg.taskId;
        saveQueueManager.removeFromSaveQueue(state, nodeId, taskId);
      })

      // uploadFile
      .addCase(uploadFile.pending, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;
        const fileId = action?.meta?.arg.fileId; // fileId is used as taskId here

        if (!nodeId || !fileId) {
          console.error("ERROR: image upload pending parameter error");
          return;
        }

        // Update task status
        saveQueueManager.updateTaskStatus(state, nodeId, fileId, {saving: true});

        //console.debug("pending: "+fileId+" "+Date.now());
      })
      .addCase(uploadFile.rejected, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;
        const fileId = action?.meta?.arg.fileId; // fileId is used as taskId here

        if (!nodeId || !fileId) {
          console.error("ERROR: image upload rejected parameter error");
          return;
        }

        const retryableErrorCodes = {
          "-1": true,  // exception on network error
          "408": true, // timeout
        };
        let isErrorRetryable = retryableErrorCodes[String(action?.error?.message || "")] === true;

        // Handle possible 403
        if (action?.error?.message === "403") {
          state.permissionDenied = true;
          isErrorRetryable = false;
        }

        // Update task status
        saveQueueManager.updateTaskStatus(
          state,
          nodeId,
          fileId,
          { saving: false,
            failed: isErrorRetryable,
            permanentlyFailed: !isErrorRetryable
          }
        );

        //console.debug("rejected: "+fileId+" "+Date.now());
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;
        const fileId = action?.meta?.arg.fileId; // fileId is used as taskId here
        
        if (!nodeId || !fileId) {
          console.error("ERROR: image upload fulfilled parameter error");
          return;
        }

        // Attach newely added file (or image) to parent node
        const node = recursiveFindNode(state.data, nodeId);

        // In some odd cases the nodes is gone. BE will add it, so we should too.
        if (!node.nodes) node.nodes = [];

        if (action.payload.index !== undefined) {
          // push at specific index (action.payload.index)
          node.nodes.splice(action.payload.index, 0, action.payload);
        } else {
          node.nodes.push(action.payload);
        }

        if (action.payload.type === 'image') {
          state.documentImagesByNodeId[action.payload.id] = action.payload;
        }

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);

        // Remove file from saveCtx
        const documentSaveCtx = action?.meta?.arg.documentSaveCtx;
        if (documentSaveCtx?.nodes?.[nodeId]?.files?.[fileId]) {
          const saveCtxNodes = documentSaveCtx.nodes;
          const saveCtxFiles = saveCtxNodes[nodeId].files;
          //console.debug("removing "+fileId+" from saveCtx");
          delete saveCtxFiles[fileId];

          // Clean empty
          if (Object.keys(saveCtxFiles).length === 0) {
            delete saveCtxNodes[nodeId].files;
          }
          if (Object.keys(saveCtxNodes[nodeId]).length === 0) {
            delete saveCtxNodes[nodeId];
          }
          if (Object.keys(saveCtxNodes).length === 0) {
            delete documentSaveCtx.nodes;
          }

          // DEBUG
          /*
          let dbgOutput = [];
          for (const dNId in saveCtxNodes) {
            const dN = saveCtxNodes[dNId];
            let dNFiles = "NO";
            if (dN.files) {
              dNFiles = [];
              for (const dFId in dN.files) {
                dNFiles.push(dFId);
              }
            }
            dbgOutput.push({id: dNId, files: dNFiles});
          }
          console.debug("ctxFiles => "+ JSON.stringify(dbgOutput));
          */
        }

        // Remove from save queue
        saveQueueManager.removeFromSaveQueue(state, nodeId, fileId);

        //console.debug("fulfilled: "+fileId+" "+Date.now());
      })

      // Checkbox
      .addCase(updateCheckbox.pending, (state, action) => {
        handleNodePending(state, action);
      })
      .addCase(updateCheckbox.rejected, (state, action) => {
        handleNodeRejected(state,action);
      })
      .addCase(updateCheckbox.fulfilled, (state, action) => {
        handleNodeFulfilled(state,action);
      })
      .addCase(updateSelectionListNode.pending, (state, action) => {
        handleNodePending(state,action);
      })
      .addCase(updateSelectionListNode.rejected, (state, action) => {
        handleNodeRejected(state,action);
      })
      .addCase(updateSelectionListNode.fulfilled, (state, action) => {
        handleNodeFulfilled(state, action);
      })
      .addCase(updateDateTime.pending, (state, action) => {
        handleNodePending(state,action);
      })
      .addCase(updateDateTime.rejected, (state, action) => {
        handleNodeRejected(state,action);
      })
      .addCase(updateDateTime.fulfilled, (state, action) => {
        handleNodeFulfilled(state, action);
      })
      .addCase(updateTableRow.pending, (state, action) => {
        handleNodePending(state,action);

        if (action.meta.arg.isModal) {
          state.updatedRowsByNodeId[action.meta.arg.nodeId] = true;
        }

      })
      .addCase(updateTableRow.rejected, (state, action) => {
        console.log('rejected updateTableRow');
        handleNodeRejected(state,action);

        if (action.meta.arg.isModal) {
          delete state.updatedRowsByNodeId[action.meta.arg.nodeId];
        }

      })
      .addCase(updateTableRow.fulfilled, (state, action) => {
        handleNodeFulfilled(state, action);

        if (action.meta.arg.isModal) {

          const { nodeId } = action.meta.arg;
          const { values } = action.payload;

          delete state.updatedRowsByNodeId[action.meta.arg.nodeId];

          if (state.rowStates?.[nodeId]) {
            state.rowStates[nodeId].values = values;
          }
        }

      })
      .addCase(saveSignature.pending, (state, action) => {
        handleNodePending(state,action);
      })
      .addCase(saveSignature.rejected, (state, action) => {
        handleNodeRejected(state,action);
      })
      .addCase(saveSignature.fulfilled, (state, action) => {
        handleNodeFulfilled(state, action);
      })
      .addCase(setSigner.pending, (state) => {
        state.signerStatus = 'pending';
      })
      .addCase(setSigner.rejected, (state) => {
        state.signerStatus = 'error';
      })
      .addCase(setSigner.fulfilled, (state, action) => {
        state.signerStatus = 'idle';
        state.signatureNodeId = null;
        state.shownSignatureNodeModalName = null;
        toggleModalFade(false);

        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) return;

        // Attach newly added input-select node to parent node
        const node = recursiveFindNode(state.data, nodeId);
      
        node.signingRightsId = action.payload.signingRightsId;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);
      })
      .addCase(updateImage.pending, (state, action) => {
        state.imageStatus = 'pending';

        /**
         * Update data in pending, then updates are handled in right order.
         * Requests are fulfilled in random order.
         * This doesn't work if some requests are failed. Later 
         */
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) return;

        const node = recursiveFindNode(state.data, nodeId);

        node.title = action.meta.arg.title;

        if (action.meta.arg.fullSize !== undefined) {
          node.fullSize = action.meta.arg.fullSize;
        }

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);

      })
      .addCase(updateImage.fulfilled, (state) => {
        state.imageStatus = 'idle';
      })
      .addCase(updateImage.rejected, (state) => {
        state.imageStatus = 'error';
        // Reset values? We don't know real values.
      })
      .addCase(rotateImage.pending, (state) => {
        state.imageStatus = 'rotating';
      })
      .addCase(rotateImage.fulfilled, (state, action) => {
        state.imageStatus = 'idle';

        const nodeId = action.payload.id;

        if (!nodeId) return;

        const node = recursiveFindNode(state.data, nodeId);

        node.modified_ts = action.payload.modified_ts;
        node.rotation = action.payload.rotation;
        node.filename = action.payload.filename;
        node.originalFilename = action.payload.originalFilename; // Not sure if this is needed

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);
      })
      .addCase(rotateImage.rejected, (state) => {
        state.imageStatus = 'error';
        // Reset values? We don't know real values.
      })
      .addCase(updateCoverImage.pending, (state, action) => {
        state.imageStatus = 'pending';
        state.data.cover_image = action.meta.arg.cover_image;
      })
      .addCase(updateCoverImage.fulfilled, (state) => {
        state.imageStatus = 'idle';
      })
      .addCase(updateCoverImage.rejected, (state) => {
        state.imageStatus = 'error';
      })
      .addCase(deleteImage.pending, (state) => {
        state.imageStatus = 'deleting';
      })
      .addCase(deleteImage.fulfilled, (state, action) => {
        state.imageStatus = 'idle';

        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) {
          console.error("ERROR: deleteImage fulfilled parameter error");
          return;
        }

        const parentNodeId = state?.nodesById?.[nodeId]?.parentNodeId;
        if (!parentNodeId) return;

        // Find the parent of the deleted node
        const node = recursiveFindNode(state.data, parentNodeId);

        // Helpers for finding the image, which is going to be shown next
        let deletedNodeFound = false;
        let nextImageToBeShown = null;

        // Recreate child nodes (skip the deleted one)
        let updatedChildNodes = [];
        for (const childNode of node.nodes) {
          if (deletedNodeFound && !nextImageToBeShown) {
            nextImageToBeShown = childNode.id;
          }
          if (childNode.id !== nodeId) {
            updatedChildNodes.push(childNode);
          } else {
            deletedNodeFound = true;
          }
        }
        node.nodes = updatedChildNodes;

        // Set the next image to be shown
        if (!nextImageToBeShown && node.nodes.length) {
          // Last (but not the only one) was deleted, show currently last one next
          nextImageToBeShown = node.nodes[node.nodes.length-1].id;
        }
        state.lightboxImageNodeId = nextImageToBeShown;

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);

        // Make sure we have the node deleted also from the secondary data structure
        if (state.nodesById[nodeId]) {
          delete state.nodesById[nodeId];
        }

        if (state.documentImagesByNodeId[nodeId]) {
          delete state.documentImagesByNodeId[nodeId];
        }

      })
      .addCase(deleteImage.rejected, (state) => {
        state.imageStatus = 'error';
        // Reset values? We don't know real values.
      })

      .addCase(moveImage.pending, (state, action) => {
        state.imageStatus = 'pending';

        const parentNodeId = action?.meta?.arg.parentNodeId
        const node = recursiveFindNode(state.data, parentNodeId);

        const from = action?.meta?.arg.fromIndex
        const to = action?.meta?.arg.toIndex
        const fromNode = node.nodes[from];

        node.nodes.splice(from, 1);
        node.nodes.splice(to, 0, fromNode);

        generateFlattedNodeStructuresPartially(state, node);
      })

      .addCase(moveImage.fulfilled, (state) => {
        state.imageStatus = 'idle';
      })

      .addCase(moveImage.rejected, (state, action) => {
        state.imageStatus = 'error';

        const parentNodeId = action?.meta?.arg.parentNodeId
        const node = recursiveFindNode(state.data, parentNodeId);

        // Revert changes made in pending
        const from = action?.meta?.arg.toIndex
        const to = action?.meta?.arg.fromIndex
        const fromNode = node.nodes[from];

        node.nodes.splice(from, 1);
        node.nodes.splice(to, 0, fromNode);

        generateFlattedNodeStructuresPartially(state, node);
      })
      
      .addCase(moveImageToSection.pending, (state) => {
        state.imageMoveStatus = 'pending';
      })

      .addCase(moveImageToSection.fulfilled, (state, action) => {
        state.imageMoveStatus = 'idle';
        
        const originalParentNodeId = action?.meta?.arg.originalParentNodeId;
        const nodeId = action?.meta?.arg.originalNodeId;
        const newParentNodeId = action?.meta?.arg.newParentNodeId;

        // Find the parents of the node
        const originalParentNode = recursiveFindNode(state.data, originalParentNodeId);
        const newParentNode = recursiveFindNode(state.data, newParentNodeId);

        // Recreate child nodes (skip the moved one and save it for push to new node)
        let updatedChildNodes = [];
        let movedNode = {};
        for (const childNode of originalParentNode.nodes) {
          if (childNode.id !== nodeId) {
            updatedChildNodes.push(childNode);
          } else {
            movedNode = childNode;
          }
        }
        
        originalParentNode.nodes = updatedChildNodes;
        movedNode.parentNodeId = newParentNodeId;
        newParentNode.nodes.push(movedNode)

        generateFlattedNodeStructures(state)
      })

      .addCase(moveImageToSection.rejected, (state) => {
        state.imageMoveStatus = 'error';
      })

      // Text (area and field + maybe markings)
      .addCase(askAISuggestion.pending, (state, action) => {
        state.suggestionNodeId = action.meta.arg.nodeId;
        if (action.meta.arg.fileId) {
          if (!state.suggestionStatus[action.meta.arg.nodeId]) {
            state.suggestionStatus[action.meta.arg.nodeId] = {};
          }
          state.suggestionStatus[action.meta.arg.nodeId][action.meta.arg.fileId] = 'loading';
        } else {
          state.suggestionStatus[action?.meta?.arg.nodeId] = 'loading';
        }
        state.suggestionText = {};
      })
      .addCase(askAISuggestion.rejected, (state, action) => {
        if (action.meta.arg.fileId) {
          if (!state.suggestionStatus[action.meta.arg.nodeId]) {
            state.suggestionStatus[action.meta.arg.nodeId] = {};
          }
          state.suggestionStatus[action.meta.arg.nodeId][action.meta.arg.fileId] = 'error';
        } else {
          state.suggestionStatus[action?.meta?.arg.nodeId] = 'error';
        }
      })
      .addCase(askAISuggestion.fulfilled, (state, action) => {
        if (action.meta.arg.fileId) {
          if (!state.suggestionStatus[action.meta.arg.nodeId]) {
            state.suggestionStatus[action.meta.arg.nodeId] = {};
          }
          state.suggestionStatus[action.meta.arg.nodeId][action.meta.arg.fileId] = 'idle';
        } else {
          state.suggestionStatus[action?.meta?.arg.nodeId] = 'idle';
        }

        if (action.meta.arg.autoAccept) {
          const formattedText = formatAISuggestionText(action.payload.text);

          if (action.meta.arg.fileId) {
            if (!state.acceptedSuggestion[action.meta.arg.nodeId]) {
              state.acceptedSuggestion[action.meta.arg.nodeId] = {};
            }
            if (!state.suggestionHistory[action.meta.arg.nodeId]) {
              state.suggestionHistory[action.meta.arg.nodeId] = {};
            }

            if (action.meta.arg.text) {
              // History could also be array to store whole history...
              state.suggestionHistory[action.meta.arg.nodeId][action.meta.arg.fileId] = action.meta.arg.text;
            }

            state.acceptedSuggestion[action.meta.arg.nodeId][action.meta.arg.fileId] = {text: formattedText, format: action.meta.arg.format};
          } else {
            const node = state.nodesById[action.meta.arg.nodeId];
            const originalText = node?.value?.text || node?.text || node?.title;
            state.suggestionHistory[action.meta.arg.nodeId] = originalText;
            state.acceptedSuggestion[action.meta.arg.nodeId] = {text: formattedText, format: action.meta.arg.format};
          }
        } else {
          state.suggestionText[action.meta.arg.nodeId] = {text: action.payload.text, format: action.meta.arg.format};
        }
      })
      .addCase(askAIImageTitle.pending, (state, action) => {
        state.suggestionNodeId = action.meta.arg.nodeId;
        if (action.meta.arg.fileId) {
          if (!state.suggestionStatus[action.meta.arg.nodeId]) {
            state.suggestionStatus[action.meta.arg.nodeId] = {};
          }
          state.suggestionStatus[action.meta.arg.nodeId][action.meta.arg.fileId] = 'loading';
        } else {
          state.suggestionStatus[action?.meta?.arg.nodeId] = 'loading';
        }
        state.suggestionText = {};
      })
      .addCase(askAIImageTitle.rejected, (state, action) => {
        if (action.meta.arg.fileId) {
          if (!state.suggestionStatus[action.meta.arg.nodeId]) {
            state.suggestionStatus[action.meta.arg.nodeId] = {};
          }
          state.suggestionStatus[action.meta.arg.nodeId][action.meta.arg.fileId] = 'error';
        } else {
          state.suggestionStatus[action?.meta?.arg.nodeId] = 'error';
        }
      })
      .addCase(askAIImageTitle.fulfilled, (state, action) => {
        if (action.meta.arg.fileId) {
          if (!state.suggestionStatus[action.meta.arg.nodeId]) {
            state.suggestionStatus[action.meta.arg.nodeId] = {};
          }
          state.suggestionStatus[action.meta.arg.nodeId][action.meta.arg.fileId] = 'idle';
        } else {
          state.suggestionStatus[action?.meta?.arg.nodeId] = 'idle';
        }

        if (action.meta.arg.autoAccept) {
          const formattedText = action.payload.text; // formatAISuggestionText(action.payload.text);

          if (action.meta.arg.fileId) {
            if (!state.acceptedSuggestion[action.meta.arg.nodeId]) {
              state.acceptedSuggestion[action.meta.arg.nodeId] = {};
            }
            if (!state.suggestionHistory[action.meta.arg.nodeId]) {
              state.suggestionHistory[action.meta.arg.nodeId] = {};
            }

            if (action.meta.arg.text) {
              // History could also be array to store whole history...
              state.suggestionHistory[action.meta.arg.nodeId][action.meta.arg.fileId] = action.meta.arg.text;
            }

            state.acceptedSuggestion[action.meta.arg.nodeId][action.meta.arg.fileId] = {text: formattedText, format: action.meta.arg.format};
          } else {
            const node = state.nodesById[action.meta.arg.nodeId];
            const originalText = node?.value?.text || node?.text || node?.title;
            state.suggestionHistory[action.meta.arg.nodeId] = originalText;
            state.acceptedSuggestion[action.meta.arg.nodeId] = {text: formattedText, format: action.meta.arg.format};
          }
        } else {
          state.suggestionText[action.meta.arg.nodeId] = {text: action.payload.text, format: action.meta.arg.format};
        }
      })
      .addCase(updateText.pending, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;
        const taskId = action?.meta?.arg.taskId;

        if (!nodeId) return;

        // Update task status
        saveQueueManager.updateTaskStatus(state, nodeId, taskId, {saving: true});
      })
      .addCase(updateText.rejected, (state, action) => {
        handleNodeRejected(state, action);
      })
      .addCase(updateText.fulfilled, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) return;

        // Find the node we're going to update
        const node = recursiveFindNode(state.data, nodeId);

        //Prevent crash when trying to save text and leaving before it saves
        if (!node) return;

        if (node.type === "note") {
          for (const key in action.payload) {
            node[key] = action.payload[key];
          }
        } else {
          // Setting node.value = action.payload wont work, because
          // , server returns value keys prefixed with 'value.'
          // So strip 'value.' from keys and update the changes
          let strippedValue = {};
          for (const dotK in action.payload) {
            const split = dotK.split('.');
            if (split.length === 2 && split[0] === 'value') {
              strippedValue[split[1]] = action.payload[dotK];
            }
          }
          // Update node
          node.value = {
            ...node.value,
            ...strippedValue
          };
        }

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);

        // Remove from save queue
        const taskId = action?.meta?.arg.taskId;
        saveQueueManager.removeFromSaveQueue(state, nodeId, taskId);
      })
      .addCase(addNote.fulfilled, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) {
          console.error("ERROR: addNote fulfilled parameter error");
          return;
        }

        // Attach newely added note to parent node
        const node = recursiveFindNode(state.data, nodeId);
        const newNoteNode = {...action.payload};
        node.nodes.push(newNoteNode);

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);

        // Set new note text field to be in edit mode
        state.editingTextNodeId = action.payload.id;
      })
      .addCase(deleteNote.pending, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) {
          console.error("ERROR: deleteNote pending parameter error");
          return;
        }

        saveQueueManager.removeWholeNodeFromSaveQueue(state, nodeId);
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) {
          console.error("ERROR: deleteNote fulfilled parameter error");
          return;
        }

        const parentNodeId = state?.nodesById?.[nodeId]?.parentNodeId;
        if (!parentNodeId) return;

        // Find the parent of the deleted node
        const node = recursiveFindNode(state.data, parentNodeId);

        // Recreate child nodes (skip the deleted one)
        let updatedChildNodes = [];
        for (const childNode of node.nodes) {
          if (childNode.id !== nodeId) {
            updatedChildNodes.push(childNode);
          }
        }
        node.nodes = updatedChildNodes;

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);

        // Make sure we have the node deleted also from the secondary data structure
        if (state.nodesById[nodeId]) {
          delete state.nodesById[nodeId];
        }

        // Close edit mode
        state.editingTextNodeId = null;
      })
      .addCase(addTableRowFromAddRowModal.pending, (state) => {
        state.addRowStatus = 'saving';
      })
      .addCase(addTableRowFromAddRowModal.fulfilled, (state, action) => {
        const nodeId = state.tableNodeForAddRowModal.id;

        if (!nodeId) {
          console.error("ERROR: saveCurrentTableNodeRow fulfilled parameter error");
          return;
        }

        // Attach newely added note to parent node
        const node = recursiveFindNode(state.data, nodeId);
        const newTableRowNode = {...action.payload};

        if (!node.nodes) {
          node.nodes = [];
        }

        node.nodes.push(newTableRowNode);

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);

        state.addRowStatus = 'idle';
      })
      .addCase(addTableRowFromAddRowModal.rejected, (state) => {
        state.addRowStatus = 'error';
      })

      .addCase(deleteTableRow.pending, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) {
          console.error("ERROR: deleteTableRow pending parameter error");
          return;
        }

        saveQueueManager.removeWholeNodeFromSaveQueue(state, nodeId);
      })
      .addCase(deleteTableRow.fulfilled, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) {
          console.error("ERROR: deleteTableRow fulfilled parameter error");
          return;
        }

        const parentNodeId = state?.nodesById?.[nodeId]?.parentNodeId;
        if (!parentNodeId) return;

        // Find the parent of the deleted node
        const node = recursiveFindNode(state.data, parentNodeId);

        // Recreate child nodes (skip the deleted one)
        let updatedChildNodes = [];
        for (const childNode of node.nodes) {
          if (childNode.id !== nodeId) {
            updatedChildNodes.push(childNode);
          }
        }
        node.nodes = updatedChildNodes;

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);

        // Make sure we have the node deleted also from the secondary data structure
        if (state.nodesById[nodeId]) {
          delete state.nodesById[nodeId];
        }
      })

      // markReady
      .addCase(markReady.fulfilled, (state, action) => {
        state.data.completed = action.payload.completed;
        state.showRequiredNodeHighlight = false;
      })

      // updateDocumentDate
      .addCase(updateDocumentDate.fulfilled, (state, action) => {
        state.data.date_ts = action.payload.date_ts;
      })

      // downloadPdf
      .addCase(downloadDocument.pending, (state,action) => {
        state.downloadDocumentStatus = 'loading';
        state.documentPDFUrl = "";
        state.documentFormatLabel = action.meta.arg.formatLabel;
        
      })
      .addCase(downloadDocument.fulfilled, (state, action) => {
        state.downloadDocumentStatus = 'idle';
        state.documentPDFUrl = action.payload;
        state.showRequiredNodeHighlight = false;
      })
      .addCase(downloadDocument.rejected, (state) => {
        state.downloadDocumentStatus = 'error';
      })

      //lockDocument
      .addCase(lockDocument.pending, (state) => {
        state.lockStatus = 'saving';
      })
      .addCase(lockDocument.fulfilled, (state, action) => {
        state.data.locked_by = action.payload.locked_by;
        state.data.locked_ts = action.payload.locked_ts;
        state.data.locker_name = action.payload.locker_name;
        state.data.locker_email = action.payload.locker_email;
        state.lockStatus = 'idle';
      })
      .addCase(lockDocument.rejected, (state) => {
        state.lockStatus = 'error';
      })

      //unlockDocument
      .addCase(unlockDocument.pending, (state) => {
        state.unlockStatus = 'saving';
      })
      .addCase(unlockDocument.fulfilled, (state, action) => {
        state.data.locked_by = action.payload.locked_by;
        state.data.locked_ts = action.payload.locked_ts;
        state.data.unlocked_by = action.payload.unlocked_by;
        state.data.unlocked_ts = action.payload.unlocked_ts;
        state.data.unlocker_name = action.payload.unlocker_name;
        state.data.unlocker_email = action.payload.unlocker_email;

        state.unlockStatus = 'idle';
      })
      .addCase(unlockDocument.rejected, (state) => {
        state.unlockStatus = 'error';
      })

      // printSettings
      .addCase(savePrintSettings.pending, (state) => {
        state.savePrintSettingsStatus = 'loading';
      })
      .addCase(savePrintSettings.fulfilled, (state) => {
        state.savePrintSettingsStatus = 'idle';
        state.data.printSettings = state.currentPrintSettings;
      })
      .addCase(savePrintSettings.rejected, (state) => {
        state.savePrintSettingsStatus = 'idle';
      })

      // updateFile (title update)
      .addCase(updateFile.pending, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;
        const taskId = action?.meta?.arg.taskId;

        if (!nodeId) return;

        // Update task status
        saveQueueManager.updateTaskStatus(state, nodeId, taskId, {saving: true});
      })
      .addCase(updateFile.rejected, (state, action) => {
        handleNodeRejected(state, action);
      })
      .addCase(updateFile.fulfilled, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) return;

        // Find the node and update it
        const node = recursiveFindNode(state.data, nodeId);

        if (action.payload.title !== undefined) {
          node.title = action.payload.title;
          //We update modified related data only when updating title
          node.modified_ts = action.payload.modified_ts
          node.modifier_name = action.payload.modifier_name
          node.modifier_email = action.payload.modifier_email
          node.modifier_id = action.payload.modifier_id
        }

        if (action.payload.public !== undefined) {
          node.public = action.payload.public;
        }
        
        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);

        // Remove from save queue
        const taskId = action?.meta?.arg.taskId;
        saveQueueManager.removeFromSaveQueue(state, nodeId, taskId);
      })

      // Delete file
      .addCase(deleteFile.fulfilled, (state, action) => {
        const nodeId = action?.meta?.arg.nodeId;

        if (!nodeId) {
          console.error("ERROR: deleteNote fulfilled parameter error");
          return;
        }

        const parentNodeId = state?.nodesById?.[nodeId]?.parentNodeId;
        if (!parentNodeId) return;

        // Find the parent of the deleted node
        const node = recursiveFindNode(state.data, parentNodeId);

        // Recreate child nodes (skip the deleted one)
        let updatedChildNodes = [];
        for (const childNode of node.nodes) {
          if (childNode.id !== nodeId) {
            updatedChildNodes.push(childNode);
          }
        }
        node.nodes = updatedChildNodes;

        // Reset completed state on modify
        state.data.completed = false;

        // Reconstruct node data for rendering
        generateFlattedNodeStructuresPartially(state, node);

        // Make sure we have the node deleted also from the secondary data structure
        if (state.nodesById[nodeId]) {
          delete state.nodesById[nodeId];
        }
      })
      
      .addCase(fetchFollowers.pending, (state) => {
        state.followStatus = 'loading';
      })
      .addCase(fetchFollowers.fulfilled, (state, action) => {
        state.followStatus = 'idle';
        state.followers = action.payload.followers;
        state.followEnabled = action.payload.followEnabled;
      })
      .addCase(fetchFollowers.rejected, (state) => {
        state.followStatus = 'error';
      })
      .addCase(follow.pending, (state) => {
        state.followStatus = 'saving';
      })
      .addCase(follow.fulfilled, (state, action) => {
        state.followStatus = 'idle';
        state.followers.push({id: action.meta.arg.userId})
      })
      .addCase(follow.rejected, (state) => {
        state.followStatus = 'error';
      })
      .addCase(unfollow.pending, (state) => {
        state.followStatus = 'saving';
      })
      .addCase(unfollow.fulfilled, (state, action) => {
        state.followStatus = 'idle';
        state.followers = state.followers.filter(f => f.id !== action.meta.arg.userId);
      })
      .addCase(unfollow.rejected, (state) => {
        state.followStatus = 'error';
      });
  },
})

// Destructure and export the plain action creators
export const {
  reset,
  collapseNode, expandNode, collapseAllSectionNodes, expandAllSectionNodes,
  resetMovedSection,
  togglePageBreakMenu,
  setTopNodeIndex,
  scrollToLastModifiedNode, resetScrollingToLastModified, resetScrollToIndex,
  scrollToFirstVisibleIndex, scrollToLastVisibleIndex,
  scrollToFirstRequiredUnfilledNode, resetScrollingToFirstRequiredUnfilledNode,
  setSignatureNodeProperties,
  // adding new files/images related (before saving them)
  setNewFilesNodeId, addNewFileByFileId, removeNewFileByFileId, markNewFileCompressed, resetNewFiles,
  // move image
  addDragImage, setImageBeingDragged, resetImageDrag, setDragImageOrder,
  // text areas
  setEditingTextNodeId,
  setTextFieldState,
  setDebouncingNode,
  deleteDebouncingNode,
  // save queue related
  addToSaveQueue, setNextSaveAttemptTs, discardTask, clearFailedTaskStatuses,
  clearZombieTasks,
  // SaveBar
  setSaveBarVisible,
  setNetworkErrorShown,
  // _other_
  setLightboxImageNodeId,
  // Table-node:
  setTableNodeForAddRowModal,
  setEditingRowNode,
  setTableNodeForAddRowModalValue,
  setRowState,
  // Files
  setFileGroupCollapse,
  // Printing
  setCurrentPrintSettings,
  setPrintSettingValue,
  setCustomPrintSettingValue,
  // Image geolocation
  setGeolocationEnabled, setGeolocation,
  
  setNewRepeatingSectionModalNode,
  setVisibleMarkingNodeIds,

  updateLastModifiedNodeTs,
  acceptSuggestion,
  rejectSuggestion,
  undoSuggestion,
  setShowRequiredNodeHighlight
} = documentSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.document.value)`
export const selectDocument = (state) => state.document.data;

export const selectDocumentId = (state) => {
  if (!state.document || !state.document.data) return null;
  return state.document.documentId;
}

export const selectIsJournalPage = (state) => {
  if (!state.document || !state.document.data) return null;
  return state.document.data.journal_page !== undefined && state.document.data.journal_page !== null;
}

export const selectIsFetchingDocument = (state) => {
  if (!state.document) return false;
  return state.document.status === 'fetchingDocument';
}

export const selectIsDocumentCompleted = (state) => {
  return state?.document?.data?.completed;
}

export const selectDocumentDate = (state) => {
  return state?.document?.data?.date_ts || state?.document?.data?.created_ts;
}

// Node (or their value) selectors
export const selectNodeIds = (state) => {
  if (!state.document || !state.document.nodeIds) return null;
  return state.document.nodeIds;
}
export const selectVisibleNodeIds = (state) => {
  if (!state.document || !state.document.visibleNodeIds) return null;
  return state.document.visibleNodeIds;
}
export const selectPrevVisibleNode = (state, nodeId) => {
  if (!state.document || !state.document.visibleNodeIds) return null;
  const currentNodeIndex = state.document.visibleNodeIds.findIndex(nId => nId === nodeId);
  if (currentNodeIndex > 0) {
    const id = state.document.visibleNodeIds[currentNodeIndex-1];
    if (id) {
      return state.document.nodesById[id];
    }
  }
  return null;
}
export const selectNodesById = (state) => {
  if (!state.document || !state.document.nodesById) return null;
  return state.document.nodesById;
}
export const selectNode = (state, nodeId) => {
  if (!state.document || !state.document.nodesById) return null;
  return state.document.nodesById[nodeId];
}
export const selectNodeValue = (state, nodeId, key) => {
  if (!state.document || !state.document.nodesById) return null;
  return state.document.nodesById?.[nodeId]?.[key];
}
export const selectParentNodeValue = (state, nodeId, key) => {
  if (!state.document || !state.document.nodesById) return null;
  const node = state.document.nodesById?.[nodeId];
  const parentNode = state.document.nodesById?.[node?.parentNodeId];

  return parentNode?.[key];
}
export const selectNodeChildNodesLength = (state, nodeId) => {
  if (!state.document || !state.document.nodesById) return null;
  return state.document.nodesById?.[nodeId]?.childNodes?.length;
}
export const selectIsNodeFirstVisible = (state, nodeId) => {
  if (!state.document || !state.document.nodesById) return null;
  return (nodeId === state.document.firstVisibleNodeId);
}
export const selectIsNodeLastVisible = (state, nodeId) => {
  if (!state.document || !state.document.nodesById) return null;
  return (nodeId === state.document.lastVisibleNodeId);
}
export const selectIsNodeLastOfItsOwnLevel = (state, nodeId) => {
  if (!state.document || !state.document.nodesById) return null;
  let node = state.document.nodesById[nodeId];
  if (!node) return null;
  let childCount = 0;
  if (node.parentNodeId === 0) {
    childCount = state.document?.data?.nodes?.length || 0;
  } else {
    let parent = state.document.nodesById[node.parentNodeId];
    if (!parent) return null;
    childCount = parent?.childNodes?.length || 0;
  }
  return (node.levelIndex === childCount - 1);
}

// Move related
export const selectScrollToIndex = (state) => {
  if (!state.document) return null;
  return state.document.scrollToIndex;
}

// Scrolling to latest modify
export const selectScrollingToLastModified = (state) => {
  if (!state.document) return null;
  return state.document.scrollingToLastModified;
}

// Sticky section header related
export const selectTopNodeIndex = (state) => {
  if (!state.document) return null;
  return state.document.topNodeIndex;
}
export const selectTopNode = (state) => {
  if (!state.document ||
      !state.document.nodesById ||
      state.document.topNodeIndex === null
  ) {
    return null;
  }
  const topNodeId = state.document.visibleNodeIds?.[state.document.topNodeIndex];
  return state.document.nodesById?.[topNodeId];
}

export const selectShownSignatureNodeModalName= (state) => {
  if (!state.document) return null;
  return state.document.shownSignatureNodeModalName;
}

export const selectSignatureNodeId = (state) => {
  if (!state.document) return null;
  return state.document.signatureNodeId;
}

export const selectSignatureNodeValue = (state) => {
  if (!state.document) return null;
  return state.document.signatureNodeValue;
}

export const selectSigningRightsId = (state) => {
  if (!state.document) return null;
  return state.document.signingRightsId;
}

// Adding files/images related
export const selectNewFilesNodeId = (state) => {
  if (!state.document) return null;
  return state.document.newFilesNodeId;
}
export const selectNewFilesByFileId = (state) => {
  if (!state.document) return null;
  return state.document.newFilesByFileId;
}
export const selectNewFile = (state, fileId) => {
  if (!state.document) return null;
  return state.document.newFilesByFileId[fileId];
}
export const selectNewFilesCompressed = (state) => {
  if (!state.document) return null;

  let hasFiles = false;
  for (const fileId in state.document.newFilesByFileId) {
    const newFile = state.document.newFilesByFileId[fileId];
    if (!newFile.compressed) return false;
    hasFiles = true;
  }
  return hasFiles;
}

export const selectDocumentImages = (state) => {
  if (!state.document) return null;
  return state.document.nodesById;
}

// How messy this is to find from 2 slices? By this way we don't have to use 2 selects in every place...
export const selectLightboxImageNodeId = (state) => state.document.lightboxImageNodeId || state.templatesEditor.lightboxImageNodeId;

// Text area related
export const selectIsEditingThisTextNode = (state, nodeId) => {
  return state?.document?.editingTextNodeId === nodeId;
}
export const selectEditingTextNodeId = (state) => {
  return state?.document?.editingTextNodeId;
}
export const selectTextFieldState = (state, nodeId) => {
  return state?.document?.textFieldStates?.[nodeId];
}
export const selectNodeTextFormat = (state, nodeId) => {
  const defaultFormat = "plaintext";
  const node = state?.document?.nodesById?.[nodeId];
  if (!node) return defaultFormat;
  if (node.type === 'input-textfield' || node.type === 'input-textarea') {
    return node?.value?.format || defaultFormat;
  } else if (node.type === 'note') {
    return node?.format || defaultFormat;
  } else {
    return defaultFormat;
  }
}
export const selectNodeText = (state, nodeId) => {
  const node = state?.document?.nodesById?.[nodeId];
  if (!node) return "";
  if (node.type === 'input-textfield' || node.type === 'input-textarea') {
    return node?.value?.text || "";
  } else if (node.type === 'note') {
    return node?.text || "";
  } else if (node.type === 'image') {
    return node?.title || "";
  } else {
    return "";
  }
}

export const selectColumnText = (state, nodeId, index) => {
  const node = state?.document?.nodesById?.[nodeId];
  if (!node || node.type !== 'table-row') return "";

  return node?.values?.[index] || "";
}
export const selectRowState = (state, nodeId) => {
  return state?.document?.rowStates?.[nodeId];
}
export const selectRowStateColumnValue = (state, nodeId, columnIndex) => {
  return state?.document?.rowStates?.[nodeId]?.values?.[columnIndex];
}
export const selectIsEditingThisRowNodeColumn = (state, nodeId, index) => {
  return state?.document?.editingRowNodeId === nodeId && state?.document?.editingRowColumnIndex === index;
}

// File groups
export const selectFileGroup = (state, parentNodeId, filename) => {
  return state?.document?.nodesById?.[parentNodeId]?.fileGroupsByFilename?.[filename];
}

// Tool for forcing rendering the react-list
export const selectRequestListRenderTs = (state) => {
  return state?.document?.requestListRenderTs;
}

// ****************************************************************************************************
// * SAVE QUEUE selectors
// ****************************************************************************************************
export const selectSaveQueueTasksByNodeId = (state) => {
  if (!state.document || !state.document.saveQueueTasksByNodeId) return null;
  return state.document.saveQueueTasksByNodeId;
}
export const selectSaveQueueNodeTaskIds = (state, nodeId) => {
  if (!state.document || !state.document.saveQueueTasksByNodeId[nodeId]) return null;
  return state.document.saveQueueTasksByNodeId[nodeId].taskIds;
}
export const selectSaveQueueNodeTasksById = (state, nodeId) => {
  if (!state.document || !state.document.saveQueueTasksByNodeId[nodeId]) return null;
  return state.document.saveQueueTasksByNodeId[nodeId].tasksById;
}
export const selectSaveQueueNodeTask = (state, nodeId, taskId) => {
  if (!state.document || !state.document.saveQueueTasksByNodeId[nodeId]) return null;
  return state.document.saveQueueTasksByNodeId[nodeId].tasksById[taskId];
}
export const selectSaveQueueNodeLastTask = (state, nodeId) => {
  if (!state.document || !state.document.saveQueueTasksByNodeId[nodeId]) return null;

  const taskCount = state.document.saveQueueTasksByNodeId[nodeId]?.taskIds.length;
  const lastTaskId = state.document.saveQueueTasksByNodeId[nodeId]?.taskIds?.[taskCount - 1];
  const lastTask = state.document.saveQueueTasksByNodeId[nodeId]?.tasksById?.[lastTaskId];

  return lastTask;
}
export const selectIsSaving = (state) => {
  if (!state.document) return false;
  return (Object.keys(state.document.saveQueueSavingNodeIds).length !== 0);
}
export const selectIsNodeSaving = (state, nodeId) => {
  if (!state.document) return false;
  return !!state.document.saveQueueSavingNodeIds[nodeId];
}
export const selectIsNodeSaveInProgress = (state, nodeId) => {
  if (!document) return false;
  return !!state.document.saveQueueSavingNodeIds[nodeId] || !!state.document.debouncingNodes[nodeId];
}
export const selectHasFailed = (state) => {
  if (!state.document) return false;
  return (Object.keys(state.document.saveQueueFailedNodeIds).length !== 0);
}
export const selectAllNodesHaveFailed = (state) => {
  if (!state.document) return false;
  if (Object.keys(state.document.saveQueueTasksByNodeId).length === 0) return false;

  for (const nodeId in state.document.saveQueueTasksByNodeId) {
    if (!state.document.saveQueueFailedNodeIds[nodeId]) {
      return false;
    }
  }

  return true;
}
export const selectAllNodesHaveFailedOrPermanentyFailed = (state) => {
  if (!state.document) return false;
  if (Object.keys(state.document.saveQueueTasksByNodeId).length === 0) return false;

  for (const nodeId in state.document.saveQueueTasksByNodeId) {
    if (
      !state.document.saveQueueFailedNodeIds[nodeId] &&
      !state.document.saveQueuePermanentlyFailedNodeIds[nodeId]
    ) {
      return false;
    }
  }

  return true;
}
export const selectSavingNodeIds = (state) => {
  if (!state.document) return null;
  return state.document.saveQueueSavingNodeIds;
}
export const selectFailedNodeIds = (state) => {
  if (!state.document) return null;
  return state.document.saveQueueFailedNodeIds;
}
export const selectHasPermanentlyFailed = (state) => {
  if (!state.document) return false;
  return (Object.keys(state.document.saveQueuePermanentlyFailedNodeIds).length !== 0);
}
export const selectHasPermanentlyFailedImageNodes = (state) => {
  if (!state.document) return false;
  for (const nodeId in state.document.saveQueuePermanentlyFailedNodeIds) {
    const node = state.document.saveQueueTasksByNodeId?.[nodeId];
    if (node && node?.type === "input-images") {
      return true;
    }
  }
  return false;
}
export const selectPermanentlyFailedTaskCount = (state) => {
  if (!state.document) return 0;

  let count = 0;

  for (const nodeId in state.document.saveQueuePermanentlyFailedNodeIds) {
    const nodeTasks = state.document.saveQueueTasksByNodeId?.[nodeId]?.tasksById || {};
    for (const taskId in nodeTasks) {
      const task = nodeTasks[taskId];
      if (task?.status?.permanentlyFailed) {
        count++;
      }
    }
  }

  return count;
}
export const selectHasNodeFailed = (state, nodeId) => {
  if (!state.document) return false;
  return !!state.document.saveQueueFailedNodeIds[nodeId];
}
export const selectNextSaveAttemptTs = (state) => {
  if (!state.document) return 0;
  return state.document.nextSaveAttemptTs;
}
export const selectSaveBarVisible = (state) => {
  if (!state.document) return false;
  return state.document.saveBarVisible;
}
export const selectTotalSaveQueueTasksInOneCycle = (state) => {
  if (!state.document) return 0;
  return state.document.totalSaveQueueTasksInOneCycle;
}
export const selectIsPermissionDenied = (state) => {
  if (!state.document) return false;
  return state.document.permissionDenied;
}
// *** end of save queue selectors ********************************************************************
export const savesInProgress = (state) => {
  const saveQueueTasks = state.document.saveQueueTasksByNodeId ? 
    Object.keys(state.document.saveQueueTasksByNodeId).length : 
    null;
  const debouncingNodes = state.document.debouncingNodes ?
    Object.keys(state.document.debouncingNodes).length : 
    null;

  return saveQueueTasks || debouncingNodes;
}

export const selectTableNodeForAddRowModal = (state) => state.document.tableNodeForAddRowModal;
export const selectAddRowStatus = (state) => state.document.addRowStatus;
export const selectColumnSumValue = (state, tableNodeId, columnIndex) => {
  const tableNode = state.document.nodesById[tableNodeId];
  if (!tableNode) {
    return '-';
  }

  let totalValue = 0;
  let isInteger = true;
    
  // Loop table rows, if rowState is set, most recent value is there
  tableNode.childNodes?.map(rowNodeId => {
    let value = null;
    const rowStateValue = selectRowStateColumnValue(state, rowNodeId, columnIndex);

    if (rowStateValue || rowStateValue === '') {
      value = rowStateValue;
    } else {
      value = selectColumnText(state, rowNodeId, columnIndex); 
    }

    const number = parseFloat(value) || 0;
    totalValue += number;

    if (value.toString().includes(".")) {
      isInteger = false;
    }
  });

  if (isNaN(totalValue)) {
    return '-';
  }

  return isInteger ? totalValue.toFixed(0) : (totalValue + 0.00000000001).toFixed(2);
}

export const selectTableNodeForAddRowModalErrors = (state) => {
  let errors = [];
  if (state.document.tableNodeForAddRowModalErrors?.length) {
    let ids = [];
    state.document.tableNodeForAddRowModalErrors.forEach(e => {
      if (!ids.includes(e.id)) {
        errors.push(e);
        ids.push(e.id);
      }
    });
  }
  return errors;
};
export const selectTableRowErrors = (state, nodeId) => {
  let errors = [];
  if (state.document.tableRowErrors?.[nodeId]?.length) {
    let ids = [];
    state.document.tableRowErrors?.[nodeId].forEach(e => {
      if (!ids.includes(e.id)) {
        errors.push(e);
        ids.push(e.id);
      }
    });
  }
  return errors;
}

export const selectDownloadDocumentStatus = (state) => state.document.downloadDocumentStatus;
export const selectCurrentPrintSettings = (state) => state.document.currentPrintSettings;
export const selectSavePrintSettingsStatus = (state) => state.document.savePrintSettingsStatus;

export const selectFollowStatus = (state) => state.document.followStatus;
export const selectFollowers = (state) => state.document.followers;
export const selectFollowEnabled = (state) => state.document.followEnabled;
export const selectGeolocationEnabled = (state) => state.document.geolocationEnabled;
export const selectGeolocation = (state) => state.document.geolocation;

export const selectNewRepeatingSectionModalNode = (state) => state.document.newRepeatingSectionModalNode;
export const selectAddRepeatingSectionStatus = (state) => state.document.addRepeatingSectionStatus;

export const selectNodeMarkingId = (state, nodeId) => {
  const marking = state.document.data?.markings?.find(m => m.content_node_id === nodeId);
  return marking?.id || null;
}

export const selectNodeMarkingFloorPlanId = (state, nodeId) => {
  const marking = state.document.data?.markings?.find(m => m.content_node_id === nodeId);
  return marking?.floor_plan_id || null;
}

export const selectMarkingRight = (state, markingId) => {
  const marking = state.document.data?.markings?.find(m => m.id === markingId);
  return marking?.right || null;
}

export const selectHasVisibleMarkingNodeIds = (state) => state.document.visibleMarkingNodeIds?.length > 0;
export const selectDocumentMarkingsFloorPlanId = (state) => state.document.data?.markings?.[0]?.floor_plan_id;

export const selectRowUpdatingByNodeId= (state, nodeId) => {
  return state.document.updatedRowsByNodeId[nodeId];
}

export const selectDocumentPDFUrl = (state) => state.document.documentPDFUrl;
export const selectDocumentFormatLabel = (state) => state.document.documentFormatLabel;



export const hasFilledRequiredNodes = createSelector(
  state => state.document.requiredIdKeyMapping,
  state => state.document.nodesById,
  (requiredIdKeyMapping, nodesById) => {

    if (Object.keys(requiredIdKeyMapping).length === 0) {
      return true;
    }

    return Object.keys(requiredIdKeyMapping).every(nodeId => !isNodeValueEmpty(nodesById[nodeId]));
  }
);



export const selectSuggestionNodeId = (state) => state.document.suggestionNodeId;
export const selectSuggestionStatus = (state, nodeId, fileId) => {
  if (fileId) {
    return state.document.suggestionStatus[nodeId]?.[fileId];
  }
  return state.document.suggestionStatus[nodeId];
}
export const selectSuggestionText = (state, nodeId) => state.document.suggestionText[nodeId];
export const selectAcceptedSuggestion = (state, nodeId, fileId) => {
  if (fileId) {
    return state.document.acceptedSuggestion[nodeId]?.[fileId];
  }
  return state.document.acceptedSuggestion[nodeId];
};
export const selectSuggestionHistory = (state, nodeId, fileId) => {
  if (fileId) {
    return state.document.suggestionHistory[nodeId]?.[fileId];
  }
  return state.document.suggestionHistory[nodeId];
};

export default documentSlice.reducer;
